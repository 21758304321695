import React, { useCallback, useEffect, useRef, useState } from "react";
import { Col, Row } from "antd";
import styled from "styled-components/macro";
import "styled-components/macro"; // DO NOT REMOVE. Necessary for using the css={`...`} prop
import { useGridOptions } from "./gridOptions";
import {
  useAutoSizeAll,
  useExportToCsv,
  useGetAllGridColumns,
  useGetAreAllColumnsVisible,
  useSetQuickFilterText,
} from "./gridApi";
import { useBoolean } from "../../helpers/useBoolean";
import { VisibilityModal } from "./show-hide-columns";
import { useToggle } from "react-use";
import {
  getStateOfGrid,
  MyAgGridReact,
  restoreStateToGrid,
} from "./my-ag-grid-react";
import { getGridHeight } from "./getGridHeight";
import _ from "lodash";
import { Ribbon } from "./ribbon";
import { CSS_TO_ALWAYS_SHOW_SCROLLBARS, useExtraGridCss } from "./styling";
import { Classes } from "@blueprintjs/core";
import { SearchBoxStore } from "./search-box-store";
import { NoData } from "./no-data";
import { useFullScreenModeEnabled } from "../../store/model-misc";
import { useGridBorderRadius } from "./use-grid-border-radius";

function AgTable({ rowData, columnDefs, onCellClicked, height }) {
  const hasData =
    rowData && columnDefs && _.size(rowData) && _.size(columnDefs);

  if (hasData) {
    return (
      <SearchBoxStore.Provider>
        <AgTableInner
          rowData={rowData}
          columnDefs={columnDefs}
          onCellClicked={onCellClicked}
          height={height}
        />
      </SearchBoxStore.Provider>
    );
  } else {
    return <NoData />;
  }
}

function AgTableInner({ rowData, columnDefs, onCellClicked, height }) {
  const g = useGrid({ onCellClicked });

  const extraGridCss = useExtraGridCss(columnDefs);
  const fullScreen = useFullScreenModeEnabled();
  const borderRadius = useGridBorderRadius();

  return (
    <>
      <Col
        className={`${Classes.ELEVATION_4}`}
        xs={g.vmShown ? 16 : 24}
        css={`
          & .ag-center-cols-viewport {
            background-color: #293742;
          }
          border-radius: ${borderRadius};
        `}
      >
        <Ribbon g={g} rowData={rowData} />
        <Row>
          <div
            css={`
              ${extraGridCss}
            `}
          >
            <g.GridWrapper height={height} fullScreen={fullScreen}>
              <MyAgGridReact
                gridRef={g.gridRef}
                rowData={rowData}
                columnDefs={columnDefs}
                {...g.gridOptions}
              />
            </g.GridWrapper>
          </div>
        </Row>
      </Col>
      {g.vmShown &&
        g.gridIsReady &&
        g.firstDataIsRendered &&
        _.size(g.columns) && (
          <>
            <Col
              xs={1}
              // css={`
              //   height: ${getGridHeightWithRibbon()};
              // `}
            >
              <></>
            </Col>
            <Col
              xs={7}
              // css={`
              //   height: ${getGridHeightWithRibbon()};
              // `}
            >
              <VisibilityModal
                columns={g.columns}
                showColumn={g.showColumn}
                hideColumn={g.hideColumn}
              />
            </Col>
          </>
        )}
    </>
  );
}

const GridInnerWrapper = styled.div`
  height: ${getGridHeight};
  width: 100%;

  .ag-root[role="grid"] {
    border: none;
  }

  ${({ alwaysShowScrollbars }) =>
    alwaysShowScrollbars && CSS_TO_ALWAYS_SHOW_SCROLLBARS}
`;

function useGrid({ onCellClicked }) {
  const gridRef = useRef();

  // const [n, setN] = useState(0);
  // const forceRerender = () => setN(nn => nn + 1);

  const [localGridState, setLocalGridState] = useState({});
  const getLocalGridState = useCallback(() => localGridState, [localGridState]);
  // const myGetStateOfGrid = useCallback( () => gridState.current, [])
  const setLocalGridStateFromActualGridState = useCallback(() => {
    setLocalGridState(getStateOfGrid(gridRef.current));
    // gridState.current = getStateOfGrid(gridRef.current);
  }, [setLocalGridState]);

  const getAllGridColumns = useGetAllGridColumns(gridRef);

  // TODO: Just use easy-peasy for all table state
  const [columns, setColumns] = useState([]);
  const refreshColumns = () => {
    // console.log("refreshColumns");
    setColumns(getAllGridColumns().map((c) => ({ ...c })));
  };
  useEffect(() => {
    if (!_.size(columns)) {
      refreshColumns();
    }
    // eslint-disable-next-line
  }, [_.size(columns)]);

  // const gridState = useRef({});
  const gridIsReady = useBoolean(true);
  const firstDataIsRendered = useBoolean(false);
  const exportToCsv = useExportToCsv(gridRef);
  const autoSizeAll = useAutoSizeAll(gridRef);
  const showColumn = useCallback(
    (column) => {
      // noinspection JSUnresolvedVariable
      gridRef.current.columnApi.setColumnVisible(column, true);
      refreshColumns();
    },
    // eslint-disable-next-line
    [gridRef, getAllGridColumns]
  );
  const hideColumn = useCallback(
    (column) => {
      // noinspection JSUnresolvedVariable
      gridRef.current.columnApi.setColumnVisible(column, false);
      refreshColumns();
    },
    // eslint-disable-next-line
    [gridRef, getAllGridColumns]
  );
  // const getIsColumnVisible = useGetIsColumnVisible(gridRef);
  const getAreAllColumnsVisible = useGetAreAllColumnsVisible(gridRef);
  const [vmShown, toggleVmShown] = useToggle(false);
  const setQuickFilterText = useSetQuickFilterText(gridRef);
  const setCategoryFilter = useSetCategoryFilter(gridRef);

  const alwaysShowScrollbars = useBoolean(false);
  const handleToggleScrollbarsVisible = useCallback(() => {
    setLocalGridStateFromActualGridState();
    alwaysShowScrollbars.toggle();
    // eslint-disable-next-line
  }, [alwaysShowScrollbars.toggle, setLocalGridStateFromActualGridState]);

  useEffect(() => {
    if (_.size(getLocalGridState())) {
      restoreStateToGrid(getLocalGridState(), gridRef.current);
    }
    // eslint-disable-next-line
  }, [getLocalGridState()]);

  const gridOptions = useGridOptions({
    onCellClicked,
    onFirstDataRendered: useCallback(() => {
      // autoSizeAll();
      firstDataIsRendered.setTrue();
      // eslint-disable-next-line
    }, [firstDataIsRendered.setTrue]),
    onColumnVisible: useCallback(() => {
      // console.log("onColumnVisible");
      refreshColumns();
      // eslint-disable-next-line
    }, [setColumns, getAllGridColumns]),
    scrollbarWidth: alwaysShowScrollbars.value ? 8 : undefined,
  });

  useEffect(() => {
    firstDataIsRendered.value && setTimeout(autoSizeAll, 300);
    // eslint-disable-next-line
  }, [firstDataIsRendered.value]);

  // const theme = useStyledTheme();
  const GridWrapper = useCallback(
    ({ children, height, fullScreen }) => {
      return (
        <div
          css={`
            width: 100%;
            height: 100%;
          `}
        >
          <GridInnerWrapper
            className="ag-theme-balham-dark"
            alwaysShowScrollbars={alwaysShowScrollbars.value}
            height={height}
            fullScreen={fullScreen}
          >
            {children}
          </GridInnerWrapper>
        </div>
      );
    },
    // eslint-disable-next-line
    [alwaysShowScrollbars.value]
  );

  return {
    gridRef,
    gridOptions,
    GridWrapper,
    exportToCsv,
    autoSizeAll,
    showColumn,
    hideColumn,
    getAllGridColumns,
    // getIsColumnVisible,
    getAreAllColumnsVisible,
    vmShown,
    toggleVmShown,
    VisibilityModal,
    setQuickFilterText,
    toggleScrollbarsVisible: handleToggleScrollbarsVisible,
    gridIsReady,
    firstDataIsRendered,
    mySetStateOfGrid: setLocalGridStateFromActualGridState,
    columns,
    setCategoryFilter,
  };
}

function useSetCategoryFilter(gridRef) {
  return (colId, filterValue) => {
    // console.log("useSetCategoryFilter");
    console.log({ filterModel: gridRef.current.api.getFilterModel() });
    const api = gridRef.current.api;
    api.setFilterModel({
      ...gridRef.current.api.getFilterModel(),
      [colId]: { value: filterValue },
    });
  };
}

// onGridReady: useCallback(() => {
//   // autoSizeAll();
//   gridIsReady.setTrue();
// }, [gridIsReady.setTrue]),
// onGridReady: useCallback(() => {
//   console.log("onGridReady", gridState.current);
//   if (gridState.current && _.size(gridState.current)) {
//     restoreStateToGrid(gridState.current, gridRef.current);
//   }
// }, []),
// onFirstDataRendered: useCallback(() => {
//   console.log("onFirstDataRendered", gridState.current);
//   if (gridState.current && _.size(gridState.current)) {
//     restoreStateToGrid(gridState.current, gridRef.current);
//   }
// }, []),
//
// onModelUpdated: useCallback(() => {
//   console.log("onModelUpdated", gridState.current);
//
//   saveStateOfGrid(gridState.current, gridRef.current);
// }, []),
// onColumnVisible: useCallback(() => {
//   console.log("onColumnVisible", gridState.current);
//   saveStateOfGrid(gridState.current, gridRef.current);
// }, []),
// onColumnResized: useCallback(() => {
//   console.log("onColumnResized", gridState.current);
//   saveStateOfGrid(gridState.current, gridRef.current);
// }, []),

export default AgTable;
