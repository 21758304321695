import React, { useCallback, useEffect, useRef, useState } from "react";
import _ from "lodash";
import { useEndpoint } from "../../helpers/use-endpoint";
import { Spin, Tooltip } from "antd";
import {
  Button,
  Card,
  Classes,
  ContextMenu,
  ContextMenuTarget,
  Elevation,
  H4,
  Icon,
  Menu,
  Popover,
  Position,
  Tab,
  Tabs
} from "@blueprintjs/core";
import { NivoPie } from "./nivo-pie";
import { NivoBar } from "./nivo-bar";
import { useBoolean } from "../../helpers/useBoolean";
import { Select } from "@blueprintjs/select";
import { TilesStore } from "./tiles-store";
import {
  AXE_NAMES,
  getAxisName,
  getSegmentId,
  getSegmentName,
  giveNames,
  isRealAxis,
  NULL_AXIS,
  SEG_NAMES,
  SEGMENT_HIERARCHY
} from "./segments";
import { integerVF } from "../AgTable/gridOptions";
import "styled-components/macro";
// eslint-disable-next-line no-restricted-imports
import { css } from "styled-components";
import AgTable from "../AgTable";
import { useFullScreenModeEnabled } from "../../store/model-misc";
import { GridContext } from "../AgTable/my-ag-grid-react-context";

const DEFAULT_TAB_ID = "visualizations";

export function RollupPage() {
  const [selectedTabId, setSelectedTabId] = useState(DEFAULT_TAB_ID);
  const handleTabChange = (newTabId, prevTabId, event) => {
    setSelectedTabId(newTabId);
  };
  const fullScreen = useFullScreenModeEnabled();
  const extraCss = fullScreen
    ? css`
        & .bp3-tab-list {
          display: none !important;
        }
        & .bp3-tab-panel {
          margin-top: 0 !important;
        }
      `
    : css`
        margin-top: -15px;
      `;
  return (
    <div
      css={`
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-start;
      `}
    >
      <Tabs
        onChange={handleTabChange}
        selectedTabId={selectedTabId}
        large
        renderActiveTabPanelOnly={true}
        css={`
          width: 100%;
          height: 100%;
          ${extraCss}
        `}
      >
        <Tab
          id="visualizations"
          title="Visualizations"
          panel={<Visualizations />}
        />
        <Tab id="detail" title="Detail" panel={<DetailView />} />
      </Tabs>
    </div>
  );
}

export function DetailView() {
  const { loading, data } = useEndpoint("/partner_stats");
  const content = loading ? <Spin size="large" /> : <DetailTable data={data} />;
  return (
    <div>
      <div>{content}</div>
    </div>
  );
}

const COLUMN_DEFS = [
  {
    headerName: "Segment",
    field: "segment",
    type: "categoryColumn"
    // pinned: "left"
  },
  { headerName: "Axis Name", field: "axisName", type: "categoryColumn" },
  { headerName: "Axis Value", field: "axisValue", type: "categoryColumn" },
  {
    headerName: "Count",
    field: "count",
    type: "integerColumn"
  }
  // {
  //   headerName: "Tier",
  //   field: "tier",
  //   type: "categoryColumn"
  // },
  // {
  //   headerName: "Region",
  //   field: "region",
  //   type: "categoryColumn"
  // },
  // {
  //   headerName: "USD Usage",
  //   field: "usd_usage",
  //   type: "categoryColumn"
  // },
  // {
  //   headerName: "Has Distrib",
  //   field: "has_distrib",
  //   type: "categoryColumn"
  // },
  // {
  //   headerName: "Mgmt Status",
  //   field: "mgmt_status",
  //   type: "categoryColumn"
  // },
  // {
  //   headerName: "TAM Owner",
  //   field: "tam_owner",
  //   type: "categoryColumn"
  // },
  // {
  //   headerName: "Sales Owner",
  //   field: "sales_owner",
  //   type: "categoryColumn"
  // },
];

function DetailTable({ data }) {
  data.forEach((d, idx) => {
    d.id = [d.t0, d.t1, d.fk, d.fv].join("|");
    d.segment = getSegmentName(d);
    d.axisName = getAxisName(d.fk);
    d.axisValue = replaceKey(d.fv);
  });
  data = _.sortBy(data, d => -d.count);
  return (
    <GridContext.Provider value={{ tableName: "rollup_detail" }}>
      <AgTable rowData={data} columnDefs={COLUMN_DEFS} height={"600px"} />
    </GridContext.Provider>
  );
}

export function Visualizations() {
  const { loading, data } = useEndpoint("/partner_stats");
  const content = loading ? (
    <Spin size="large" />
  ) : (
    <RollupContentWrapper>
      <RollupContent data={data} />
    </RollupContentWrapper>
  );
  return (
    <div>
      <div>{content}</div>
    </div>
  );
}

function RollupContentWrapper({ children }) {
  return (
    <div
      css={`
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 40px;
        position: relative;
        width: 100%;
      `}
    >
      <div
        css={`
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
          align-items: center;

          margin: -10px -20px -20px;
          & > * {
            margin: 20px;
          }

          & .bp3-card.bp3-card {
            padding: 20px;
          }
        `}
      >
        <TilesStore.Provider>{children}</TilesStore.Provider>
      </div>
    </div>
  );
}

function useTiles() {
  return TilesStore.useStoreState(s => s.tiles).map(giveNames);
}

function useTile(tileId) {
  return useTiles().find(t => t.id === tileId);
}

function RollupContent({ data }) {
  const tiles = useTiles();
  const pushTile = TilesStore.useStoreActions(a => a.pushTile);
  return (
    <>
      <Tooltip title="Add Tile">
        <Button
          intent="success"
          onClick={() => pushTile()}
          rightIcon="new-layer"
          css={`
            position: absolute;
            top: -72px;
            right: -26px;
          `}
        />
      </Tooltip>
      {tiles.map(t => (
        <Tile key={t.id} data={data} {...t} />
      ))}
    </>
  );
}

function useTileActions(tileId) {
  const toggleTileField = TilesStore.useStoreActions(a => a.toggleTileField);
  const updateTile = TilesStore.useStoreActions(a => a.updateTile);
  const removeTile = TilesStore.useStoreActions(a => a.removeTile);
  const duplicateTile = TilesStore.useStoreActions(a => a.duplicateTile);
  return {
    toggle: fieldName => {
      toggleTileField([tileId, fieldName]);
    },
    update: tileEdits => {
      updateTile({ id: tileId, ...tileEdits });
    },
    remove: () => {
      removeTile(tileId);
    },
    duplicate: () => {
      duplicateTile(tileId);
    }
  };
}

function Tile({
  data,
  //
  id,
  seg1,
  seg2,
  axis = NULL_AXIS,
  width = 600,
  height = 400,
  ignoreNA,
  normalizeBarHeights,
  //
  formatTitle = defaultFormatTitle,
  ChartComponent = DefaultChartComponent
}) {
  const chartParams = { seg1, seg2, axis, ignoreNA };
  const title = formatTitle(giveNames(chartParams));
  const tileActions = useTileActions(id);
  const tile = useTile(id);
  const chartData = makeChartData({ data, ...chartParams });

  const mouseXY = useRef(null);
  const editMenu = (
    <EditMenu tileActions={tileActions} tile={tile} data={data} />
  );
  useEffect(() => {
    if (mouseXY.current) {
      const offset = { ...mouseXY.current };
      ContextMenu.hide();
      mouseXY.current = { ...offset };
      ContextMenu.show(
        editMenu,
        { ...offset },
        () => {
          mouseXY.current = null;
        },
        true
      );
    }
  });

  return (
    <EditIcon editMenu={editMenu}>
      <Card
        elevation={Elevation.TWO}
        onContextMenu={e => {
          e.preventDefault();
          mouseXY.current = { left: e.clientX, top: e.clientY };
          ContextMenu.show(
            editMenu,
            { ...mouseXY.current },
            () => {
              mouseXY.current = null;
            },
            true
          );
        }}
      >
        {title && <H4>{title}</H4>}
        <ChartComponent
          width={width}
          height={height}
          data={chartData}
          axis={axis}
          normalizeBarHeights={normalizeBarHeights}
        />
      </Card>
    </EditIcon>
  );
}

@ContextMenuTarget
class MyCard extends React.Component {
  render() {
    const { children } = this.props;
    return <Card elevation={Elevation.TWO}>{children}</Card>;
  }
  renderContextMenu() {
    const { tileActions, tile } = this.props;
    return <EditMenu tileActions={tileActions} tile={tile} />;
  }
}

function makeChartData({ data, seg1, seg2, axis, ignoreNA }) {
  return getChartDataMaker(data)({ seg1, seg2, axis, ignoreNA });
}

function getChartDataMaker(data) {
  return ({ ignoreNA, seg1, seg2, axis }) => {
    const seg1_id = getSegmentId(seg1);
    const seg2_id = getSegmentId(seg2);

    const fos = seg => findOneStat(data, seg).count;
    const fms = seg => findManyStats(data, { fk: axis.id, ...seg });

    let ret;
    if (isRealAxis(axis)) {
      ret = sunburstDoubleBreakdown(
        ignoreNA,
        SEG_NAMES[seg1_id],
        SEG_NAMES[seg2_id],
        fms(seg1),
        fms(seg2)
      );
    } else {
      ret = pieDoubleBreakdown(
        othersName(seg1_id),
        SEG_NAMES[seg2_id],
        fos(seg1),
        fos(seg2)
      );
    }

    return ret;
  };
}

function othersName(name) {
  return `Other ${SEG_NAMES[name]}`;
}

function replaceKey(key) {
  return (
    {
      nan: "N/A",
      True: "Yes",
      False: "No"
    }[key] || key
  );
}

function defaultFormatTitle({ seg1, seg2, axis }) {
  if (isRealAxis(axis)) {
    return `${axis.name}: ${seg1.name} vs ${seg2.name}`;
  } else {
    return `${seg1.name} that are ${seg2.name}`;
  }
}

function DefaultChartComponent({ axis, ...restProps }) {
  const C = isRealAxis(axis) ? NivoBar : NivoPie;
  return <C axis={axis} {...restProps} />;
}

function selectItemRenderer(item, { handleClick, modifiers, query }) {
  if (!modifiers.matchesPredicate) {
    return null;
  }
  return (
    <Menu.Item
      active={modifiers.active}
      disabled={modifiers.disabled}
      onClick={handleClick}
      key={item.name}
      text={item.name}
      shouldDismissPopover={false}
      // label={item.name}
    />
  );
}

function EditIcon({ children, editMenu }) {
  const popoverOpen = useBoolean(false);

  return (
    <div
      css={`
        position: relative;
        top: 0;
        left: 0;
        & .bp3-popover-wrapper {
          position: absolute;
          top: -16px;
          right: -16px;
          z-index: 2;
        }
      `}
    >
      <Tooltip title="Edit" placement="right">
        <Popover
          position={Position.RIGHT_TOP}
          isOpen={popoverOpen.value}
          onClose={popoverOpen.setFalse}
          popoverClassName={Classes.ELEVATION_4}
          autoFocus={false}
        >
          <Button
            intent="primary"
            rightIcon={<Icon icon="more" />}
            css={`
              border-radius: 50%;
              background: transparent;
            `}
            onClick={popoverOpen.setTrue}
            className={Classes.ELEVATION_2}
          />
          {editMenu}
        </Popover>
      </Tooltip>
      {children}
    </div>
  );
}

const AXIS_ITEMS = Object.entries(AXE_NAMES).map(([id, name]) => ({
  id,
  name
}));

const SEGMENT_ITEMS = Object.entries(SEG_NAMES).map(([id, name]) => {
  const idParts = id.split("__");
  if (idParts.length === 1) {
    idParts.push(undefined);
  }
  const [t0, t1] = idParts;
  return { t0, t1, name };
});

const findByParent = seg =>
  SEGMENT_HIERARCHY.find(
    ({ parent: ps }) => ps.t0 === seg.t0 && ps.t1 === seg.t1
  );

const MySelect = ({ items, selected, setSelected, name, ...restProps }) => (
  <Select
    items={items}
    itemRenderer={selectItemRenderer}
    onItemSelect={setSelected}
    {...restProps}
  >
    <Button fill text={`${name}: ${selected.name}`} rightIcon="caret-down" />
  </Select>
);

function EditMenu({ tileActions, tile, data }) {
  const fos = seg => findOneStat(data, seg).count;
  const { seg1, seg2, axis } = tile;
  const setAxis = useCallback(
    v => {
      if (v.id !== axis.id) tileActions.update({ axis: v });
    },
    [axis.id, tileActions]
  );
  const setSeg1 = useCallback(
    v => {
      if (v.t0 !== seg1.t0 || v.t1 !== seg1.t1) tileActions.update({ seg1: v });
    },
    [seg1.t0, seg1.t1, tileActions]
  );
  const setSeg2 = useCallback(
    v => {
      if (v.t0 !== seg2.t0 || v.t1 !== seg2.t1) tileActions.update({ seg2: v });
    },
    [seg2.t0, seg2.t1, tileActions]
  );

  const segmentsEqual = (s1, s2) => s1.t0 === s2.t0 && s1.t1 === s2.t1;

  const isValidAxis = ax => true; //ax.id !== axis.id;
  const isValidSeg1 = seg => {
    // if (seg.t0 === seg1.t0 && seg.t1 === seg1.t1) {
    //   return false;
    // }
    if (isRealAxis(axis)) {
      return true;
    } else {
      return !!findByParent(seg);
    }
  };
  const isValidSeg2 = seg => {
    // if (seg.t0 === seg2.t0 && seg.t1 === seg2.t1) {
    //   return false;
    // }
    if (isRealAxis(axis)) {
      return true;
    } else {
      const res = findByParent(seg1);
      return !!res?.children.find(ch => ch.t0 === seg.t0 && ch.t1 === seg.t1);
    }
  };

  const giveCount = seg => ({ ...seg, count: fos(seg) });

  const itemsAxis = AXIS_ITEMS.filter(isValidAxis);
  const itemsSeg1 = _.sortBy(
    SEGMENT_ITEMS.filter(isValidSeg1).map(giveCount),
    i => -i.count
  );
  const itemsSeg2 = _.sortBy(
    SEGMENT_ITEMS.filter(isValidSeg2).map(giveCount),
    i => -i.count
  );

  useEffect(() => {
    if (itemsSeg2.length === 0) {
      setSeg1(itemsSeg1[0]);
    } else if (itemsSeg1.filter(v => segmentsEqual(v, seg1)).length === 0) {
      setSeg1(itemsSeg1[0]);
    } else if (itemsSeg2.filter(v => segmentsEqual(v, seg2)).length === 0) {
      setSeg2(itemsSeg2[0]);
    } else if (seg1.t0 === seg2.t0 && seg1.t1 === seg2.t1) {
      setSeg2(itemsSeg2[0]);
    }
  }, [
    itemsSeg1,
    itemsSeg2,
    itemsSeg2.length,
    seg1,
    seg1.t0,
    seg1.t1,
    seg2,
    seg2.t0,
    seg2.t1,
    setSeg1,
    setSeg2
  ]);

  return (
    <Menu
      large
      className="bp3-dark"
      css={`
        width: 330px;
      `}
    >
      <Menu.Divider title={"Content"} />
      <Menu.Item
        icon={"diagram-tree"}
        key={"Axis"}
        text={"Axis"}
        shouldDismissPopover={false}
        label={axis.name}
      >
        {itemsAxis.map(item => (
          <>
            <Menu.Item
              key={item.name}
              text={item.name}
              onClick={() => setAxis(item)}
              shouldDismissPopover={false}
              labelElement={
                isRealAxis(item) ? (
                  <Icon icon="alignment-bottom" />
                ) : (
                  <Icon icon="doughnut-chart" />
                )
              }
            />
            {isRealAxis(item) || <Menu.Divider />}
          </>
        ))}
      </Menu.Item>
      <Menu.Item
        icon={"left-join"}
        key={"Primary"}
        text={"Primary"}
        shouldDismissPopover={false}
        label={seg1.name}
      >
        {itemsSeg1.map(item => (
          <Menu.Item
            key={item.name}
            text={item.name}
            onClick={() => setSeg1(item)}
            shouldDismissPopover={false}
            label={integerVF(item.count)}
          />
        ))}
      </Menu.Item>
      <Menu.Item
        icon={"right-join"}
        key={"Secondary"}
        text={"Secondary"}
        shouldDismissPopover={false}
        label={seg2.name}
      >
        {itemsSeg2.map(item => (
          <Menu.Item
            key={item.name}
            text={item.name}
            onClick={() => setSeg2(item)}
            shouldDismissPopover={false}
            label={integerVF(item.count)}
          />
        ))}
      </Menu.Item>
      <Menu.Divider title={"Options"} />
      <DimensionsMenuItem tileActions={tileActions} tile={tile} />
      {isRealAxis(axis) && (
        <ExcludeNullsMenuItem tileActions={tileActions} tile={tile} />
      )}
      {isRealAxis(axis) && (
        <NormalizeBarHeightsMenuItem tileActions={tileActions} tile={tile} />
      )}
      <Menu.Divider title={"Tile"} />
      <Menu.Item
        icon="duplicate"
        intent="success"
        key={"Duplicate"}
        text={"Duplicate"}
        shouldDismissPopover={true}
        onClick={tileActions.duplicate}
      />
      <Menu.Item
        icon="trash"
        intent="danger"
        key={"Remove"}
        text={"Remove"}
        shouldDismissPopover={true}
        onClick={tileActions.remove}
      />
    </Menu>
  );
  // return (
  //   <Menu
  //     className="bp3-dark"
  //     css={`
  //       width: 400px;
  //       height: 300px;
  //     `}
  //   >
  //     <div
  //       css={`
  //         display: flex;
  //         flex-direction: column;
  //         justify-content: space-around;
  //         align-items: stretch;
  //         padding: 16px;
  //         width: 100%;
  //         height: 100%;
  //         & .bp3-popover-target {
  //           width: 100%;
  //           & > * {
  //             width: 100%;
  //           }
  //         }
  //       `}
  //     >
  //       <MySelect
  //         name={"Axis"}
  //         items={itemsAxis}
  //         selected={axis}
  //         setSelected={setAxis}
  //       />
  //       <MySelect
  //         name={"Segment 1"}
  //         items={itemsSeg1}
  //         selected={seg1}
  //         setSelected={setSeg1}
  //       />
  //       <MySelect
  //         name={"Segment 2"}
  //         items={itemsSeg2}
  //         selected={seg2}
  //         setSelected={setSeg2}
  //       />
  //     </div>
  //   </Menu>
  // );
}

function ExcludeNullsMenuItem({ tileActions, tile }) {
  return (
    <Menu.Item
      icon={"filter"}
      key={'"N/A" Counts'}
      text={'"N/A" Counts'}
      shouldDismissPopover={false}
      label={tile.ignoreNA ? "Ignoring" : "Including"}
      onClick={() => {
        tileActions.toggle("ignoreNA");
      }}
    />
  );
}
function NormalizeBarHeightsMenuItem({ tileActions, tile }) {
  return (
    <Menu.Item
      icon={"alignment-bottom"}
      key={"Heights"}
      text={"Heights"}
      shouldDismissPopover={false}
      label={tile.normalizeBarHeights ? "%" : "#"}
      onClick={() => tileActions.toggle("normalizeBarHeights")}
    />
  );
}

function DimensionsMenuItem({ tileActions, tile }) {
  let { width, height } = tile;

  if (!_.isNumber(width)) {
    width = 600;
  }

  if (!_.isNumber(height)) {
    height = 400;
  }

  return (
    <Menu.Item
      icon={"widget"}
      key={"Size"}
      text={"Size"}
      shouldDismissPopover={false}
      label={`${width}x${height}`}
    >
      <Menu.Item
        key={"Width"}
        text={
          <div
            css={`
              display: flex;
              justify-content: flex-start;
              align-items: center;
            `}
          >
            <Button
              minimal
              small
              large={false}
              rightIcon="plus"
              onClick={() => tileActions.update({ width: width + 100 })}
              css={`
                margin-right: 8px;
              `}
            />
            <Button
              minimal
              small
              large={false}
              rightIcon="minus"
              onClick={() => tileActions.update({ width: width - 100 })}
            />
          </div>
        }
        shouldDismissPopover={false}
        label={"Width"}
      />
      <Menu.Item
        key={"Height"}
        text={
          <div
            css={`
              display: flex;
              justify-content: flex-start;
              align-items: flex-start;
            `}
          >
            <Button
              minimal
              small
              large={false}
              rightIcon="plus"
              onClick={() => tileActions.update({ height: height + 100 })}
              css={`
                margin-right: 8px;
              `}
            />
            <Button
              minimal
              small
              large={false}
              rightIcon="minus"
              onClick={() => tileActions.update({ height: height - 100 })}
            />
          </div>
        }
        shouldDismissPopover={false}
        label={"Height"}
      />
    </Menu.Item>
  );
}

function makeBreakdown(
  items,
  keyFields = ["id"],
  valueFields = ["value"],
  giveLeavesEmptyChildren = false
) {
  if (!_.isArray(items)) {
    items = Object.entries(items);
  }
  return items.map(([k, v]) => {
    let ret;
    if (_.isArray(v)) {
      ret = { children: makeBreakdown(v) };
    } else {
      ret = Object.fromEntries(valueFields.map(vf => [vf, v]));
      if (giveLeavesEmptyChildren) {
        ret.children = [];
      }
    }
    keyFields.forEach(field => {
      ret[field] = replaceKey(k);
    });
    return ret;
  });
}

function pieDoubleBreakdown(otherName, segmentName, parentCount, segmentCount) {
  return makeBreakdown(
    [
      [segmentName, segmentCount],
      [otherName, parentCount - segmentCount]
    ],
    ["id", "label"]
  );
}

function sunburstDoubleBreakdown(
  ignoreNA,
  otherName,
  segmentName,
  otherCounts,
  segmentCounts,
  keyField = "id",
  valueField = "value"
) {
  return {
    [keyField]: "does not matter",
    children: [
      {
        [keyField]: segmentName,
        children: makeBreakdown(
          segmentCounts
            .filter(el => el.count > 0)
            .map(({ fv, count }) => [fv, count]),
          [keyField],
          [valueField]
          // true
        ).filter(el => !(ignoreNA && el[keyField] === "N/A"))
      },
      {
        [keyField]: otherName,
        children: makeBreakdown(
          otherCounts
            .filter(el => el.count > 0)
            .map(({ fv, count }) => [fv, count]),
          [keyField],
          [valueField]
          // true
        ).filter(el => !(ignoreNA && el[keyField] === "N/A"))
      }
    ]
  };
}

function findOneStat(data, matcher) {
  return data.find(filterFunFactory(matcher));
}

function findManyStats(data, matcher) {
  return data.filter(filterFunFactory(matcher));
}

function filterFunFactory({ t0, t1 = "", fk = "" }) {
  return v => v.t0 === t0 && v.t1 === t1 && v.fk === fk;
}
