import { Spin } from "antd";
import React from "react";
import { useEnsureScoresAndShapesData } from "../helpers/useEnsurePartnersData";
import AgTable from "./AgTable";
import { GridContext } from "./AgTable/my-ag-grid-react-context";

export function ScoresAndShapesTable() {
  const { loading, data } = useEnsureScoresAndShapesData();
  return loading ? (
    <Spin size="large" />
  ) : (
    <ScoresAndShapesTableInner data={data} />
  );
}

function ScoresAndShapesTableInner({ data }) {
  data.forEach((d, idx) => {
    d.id = idx;
  });
  return (
    <GridContext.Provider value={{ tableName: "scores_and_shapes" }}>
      <AgTable rowData={data} columnDefs={COLUMN_DEFS} height={"600px"} />
    </GridContext.Provider>
  );
}

const COLUMN_DEFS = [
  {
    headerName: "Score Bucket",
    field: "Score Bucket",
    type: "categoryColumn",
  },
  {
    headerName: "Total",
    field: "Total",
    type: "integerColumn",
  },
  {
    headerName: "Bumpy",
    field: "Bumpy",
    type: "integerColumn",
  },
  {
    headerName: "Hill",
    field: "Hill",
    type: "integerColumn",
  },
  {
    headerName: "Plateau",
    field: "Plateau",
    type: "integerColumn",
  },
  {
    headerName: "Growing",
    field: "Growing",
    type: "integerColumn",
  },
];
