import { useParams } from "react-router";
import React from "react";
import { NivoLineFromEndpoint } from "./components/AgTable/nivo-line";

export function NapoLine({ partnerId, height, width }) {
  const endpoint = `napo_history/${partnerId}`;
  return (
    <NivoLineFromEndpoint
      endpoint={endpoint}
      height={height}
      width={width}
      enableSlices={false}
      enablePoints={false}
    />
  );
}

export function NapoPage() {
  const { partnerId } = useParams();
  return <NapoLine partnerId={partnerId} height={600} width={800} />;
}
