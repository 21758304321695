import React, { useState } from "react";
import styled from "styled-components/macro";
import { H4, Tab, Tabs } from "@blueprintjs/core";
import { NapoLine } from "../../../napo";

const PopConWrapper = styled.div`
  padding: 13px 24px 23px 24px;
  height: 440px;
  width: 520px;
  overflow-y: auto;
`;

export const PopConHeader = styled(H4)`
  margin: 0;
`;

const TAB_IDS = {
  napoHistory: "napo-history"
};

export function ThePopoverContent({ partnerId }) {
  const [selectedTabId, setSelectedTabId] = useState(TAB_IDS.napoHistory);
  const handleTabChange = (newTabId, prevTabId, event) => {
    setSelectedTabId(newTabId);
  };
  return (
    <PopConWrapper>
      <Tabs
        onChange={handleTabChange}
        selectedTabId={selectedTabId}
        large
        renderActiveTabPanelOnly={true}
      >
        <Tab
          id={TAB_IDS.napoHistory}
          title="History"
          panel={<NapoLine partnerId={partnerId} height={338} width={450} />}
        />
      </Tabs>
    </PopConWrapper>
  );
}
