import { useStoreActions } from "easy-peasy";
import React from "react";
import {
  Callout,
  Classes,
  H4,
  H5,
  Menu as BPMenu,
  Popover,
  Position,
  Button,
} from "@blueprintjs/core";
import { RbnBtn } from "./AgTable/ribbon";
import * as COLORS from "../styles/colors";
import { useMe, useMeData } from "../hooks/useMe";
import "styled-components/macro";
import { useBoolean } from "../helpers/useBoolean";
import { useUserTimezone } from "../helpers/use-user-timezone";
import { useParams } from "react-router";
import { useDataRefreshStatsText } from "./data-refresh-stats";
import moment from "moment";
import "moment-timezone";

function useInsideReactRouterSwitch() {
  try {
    useParams();
    return true;
  } catch (e) {
    return false;
  }
}

export function UserSettings() {
  const { loading } = useMe();
  const meData = useMeData();
  const insideReactRouterSwitch = useInsideReactRouterSwitch();

  if (loading || !meData || !insideReactRouterSwitch) return <span>{""}</span>;
  else return <UserSettingsInner meData={meData} />;
}

function useTimeOfDay() {
  const userTz = useUserTimezone();
  const userTzHours = moment().tz(userTz).hours();
  if (userTzHours < 12) {
    return "Morning";
  } else if (userTzHours < 17) {
    return "Afternoon";
  } else {
    return "Evening";
  }
}

function UserSettingsInner({ meData }) {
  // noinspection JSUnresolvedVariable
  const handleLogout = useStoreActions((a) => a.firebase.logout);

  const timeOfDay = useTimeOfDay();

  // noinspection JSUnresolvedVariable
  const greeting = `Good ${timeOfDay}, ${meData.first_name}`;

  const dataRefreshStatsText = useDataRefreshStatsText();

  const menu = (
    <BPMenu large className="bp3-dark">
      <div
        css={`
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 16px;
        `}
      >
        <H4
          css={`
            padding: 0 !important;
            margin: 0 0 16px 0 !important;
          `}
        >
          {greeting}
        </H4>
        <Callout
          icon="predictive-analysis"
          intent="success"
          css={`
            margin: 0 0 16px 0 !important;
          `}
        >
          <H5>Latest ML Refresh</H5>
          {dataRefreshStatsText}
        </Callout>
        <Button
          data-testid="logout_button"
          intent="primary"
          icon="power"
          fill={true}
          onClick={handleLogout}
        >
          Logout
        </Button>
      </div>
    </BPMenu>
  );
  const isOpen = useBoolean(false);
  return (
    <Popover
      content={menu}
      position={Position.LEFT_TOP}
      isOpen={isOpen.value}
      onClose={isOpen.setFalse}
      popoverClassName={`${Classes.ELEVATION_4}`}
    >
      <RbnBtn
        dataTestId="account_button"
        ghost={false}
        type={"primary"}
        size="large"
        icon="user"
        tooltipDisabled={isOpen.value}
        tooltipPlacement="bottom"
        title="Settings"
        hoverColor={COLORS.primary}
        onClick={isOpen.setTrue}
      />
    </Popover>
  );
}
