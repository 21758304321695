import { useStoreState } from "../hooks/ep";
import { epStore } from "../store";
import _ from "lodash";

export function usePartnerRow(partnerId) {
  return useStoreState((s) => s.skykick.partner2data[partnerId], [partnerId]);
}

export function getPartnerRow(partnerId) {
  const partner2data = epStore.getState().skykick.partner2data;
  return _.size(partner2data) ? partner2data[partnerId] : {};
}
