import React, { useCallback } from "react";
import { Button, Menu, MenuItem, Popover, Position } from "@blueprintjs/core";
import Tooltip from "antd/es/tooltip";
import styled from "styled-components/macro";
import { useStoreActions, useStoreState } from "easy-peasy";
import _ from "lodash";

function PopoverWrapper({ content, children, className, ...rest }) {
  return (
    <Popover {...rest} content={content} portalClassName={className}>
      {children}
    </Popover>
  );
}

const StyledPopoverWrapper = styled(PopoverWrapper)`
  &&& * {
    z-index: 1000;
  }
`;

function composeMailtoLink(email, toName, fromName) {
  const et = {
    subject: "Hello From SkyKick",
    body: `
Hi ${toName}, 

I hope that you are doing well, and as it has been a while since we last connected, I wanted to catch up and discuss some of the new features of the SkyKick Backup product. Do you have time to connect any time next week?

Best,
${fromName}
`.trim()
  };
  const subject = encodeURIComponent(et.subject);
  const body = encodeURIComponent(et.body);
  return `mailto:${email}?subject=${subject}&body=${body}`;
}

function useSignedInUserDisplayName() {
  return useStoreState(s => s.firebase.red.firebase.auth.displayName);
}

export function ActionButtonCellRenderer({ data, context }) {
  const partnerContactEmail = _.toLower(data["dp__Email"]);
  const partnerCrmUrl = data["dp__PartnerCrmUrl"];
  const partnerId = data["dp__PartnerId"];
  const partnerName = data["dp__PartnerName"];
  const signedInUserFullName = useSignedInUserDisplayName();

  const starsSet = useStoreState(a => a.stars.starsSet);
  const starred = starsSet.has(partnerId);
  const starPartner = useStoreActions(a => a.stars.starPartner);
  const unstarPartner = useStoreActions(a => a.stars.unstarPartner);

  const onClickEmailPartnerContactAction = useCallback(() => {
    window.open(
      composeMailtoLink(partnerContactEmail, partnerName, signedInUserFullName)
    );
  }, [partnerContactEmail]);

  const onClickViewPartnerInCrm = useCallback(() => {
    window.open(partnerCrmUrl);
  }, [partnerCrmUrl]);

  const onClickStarsAction = useCallback(
    async ev => {
      const storeAction = starred ? unstarPartner : starPartner;
      await storeAction({ partnerId, partnerName });
    },
    [starred, starPartner, unstarPartner, partnerId, partnerName]
  );

  const exampleMenu = (
    <Menu large>
      <MenuItem
        icon="envelope"
        text="Email partner contact"
        onClick={onClickEmailPartnerContactAction}
      />
      <MenuItem
        icon="home"
        text="View partner in CRM"
        onClick={onClickViewPartnerInCrm}
      />
      <MenuItem
        icon="star"
        text={starred ? "Unstar" : "Star"}
        onClick={onClickStarsAction}
      />
    </Menu>
  );
  return (
    <span>
      <StyledPopoverWrapper
        canEscapeKeyClose
        content={exampleMenu}
        position={Position.RIGHT_BOTTOM}
      >
        <Tooltip title="Actions" placement="topLeft" mouseEnterDelay={1.5}>
          <Button
            minimal
            small
            icon="take-action"
            large={false}
            intent="primary"
          />
        </Tooltip>
      </StyledPopoverWrapper>
    </span>
  );
}
