import {
  action,
  Action,
  actionOn,
  ActionOn,
  Computed,
  computed,
  thunk,
  Thunk,
  ThunkOn,
  thunkOn,
} from "easy-peasy";
import { Injections, StoreModel } from "./model";
import {
  makeAuthorizedGetRequestToBackend,
  makeUrl,
} from "../helpers/backendApi";
import _ from "lodash";
import "moment-timezone";
import Moment from "moment";
import { extendMoment } from "moment-range";

// @ts-ignore
const moment = extendMoment(Moment);

export interface RowDatum {
  dp__PartnerId: string;
  [index: string]: any;
}

export interface SkykickInitialData {
  rowData: RowDatum[];
  inferenceData: RowDatum[];
  columnDefs: object[];
}

export interface PartnerTimeMachineData {
  date: string[];
  y: number[];
}
export interface TimeMachineData {
  [partnerId: string]: PartnerTimeMachineData;
}
export interface Point {
  x: any;
  y: any;
}

export interface SkykickModel {
  NAME: string;
  INITIAL_DATA_ENDPOINT: string;
  initialData: SkykickInitialData;
  initialDataReceived: boolean;
  initialDataLoading: boolean;
  data_refreshed_at: string;
  timeMachineData: TimeMachineData;
  onLogout: ActionOn<SkykickModel, StoreModel>;
  receiveDataRefreshedAt: Action<SkykickModel, string>;
  receiveTimeMachineData: Action<SkykickModel, TimeMachineData>;
  onLogin: ThunkOn<SkykickModel, Injections, StoreModel>;
  onInitialDataReceived: ThunkOn<SkykickModel, Injections, StoreModel>;
  receiveInitialData: Action<SkykickModel, SkykickInitialData>;
  markInitialDataReceived: Action<SkykickModel, void>;
  maybeHandleFetchInitialData: Thunk<
    SkykickModel,
    void,
    Injections,
    StoreModel
  >;
  handleFetchInitialData: Thunk<SkykickModel, void, Injections, StoreModel>;
  partner2data: Computed<SkykickModel, { [partnerId: string]: RowDatum }>;
  rowData: Computed<SkykickModel, RowDatum[]>;
  averageChurnScore: Computed<SkykickModel, number>;
  scoreHistoryDataByPartnerId: Computed<
    SkykickModel,
    (partnerId: string) => { id: string; data: Point[] }
  >;
}

const initialInitialData = () => ({
  rowData: [],
  columnDefs: [],
  inferenceData: [],
});

const skykickModel: SkykickModel = {
  INITIAL_DATA_ENDPOINT: "skykick_partners",
  NAME: "skykick",
  initialData: initialInitialData(),
  initialDataReceived: false,
  initialDataLoading: false,
  data_refreshed_at: "",
  timeMachineData: {},
  receiveDataRefreshedAt: action((state, data_refreshed_at) => {
    state.data_refreshed_at = data_refreshed_at;
  }),
  receiveTimeMachineData: action((state, time_machine_data) => {
    console.log(
      "xxx",
      time_machine_data["DBABD899-D0E2-E611-80EC-C4346BAC7B90"]
    );
    state.timeMachineData = time_machine_data;
  }),
  onInitialDataReceived: thunkOn(
    (actions) => actions.markInitialDataReceived,
    async (actions, __, { getState }) => {
      await Promise.all([
        makeAuthorizedGetRequestToBackend({
          url: makeUrl("skykick_partners_inference_data"),
        }).then((resp) => actions.receiveInitialData(resp.data)),
        makeAuthorizedGetRequestToBackend({
          url: makeUrl("time_machine_data"),
        }).then((resp) => actions.receiveTimeMachineData(resp.data)),
      ]);
    }
  ),
  onLogin: thunkOn(
    (actions, storeActions) => storeActions.me.receiveInitialData,
    async (actions, __, { getState }) => {
      if (!getState().data_refreshed_at) {
        const resp = await makeAuthorizedGetRequestToBackend({
          url: makeUrl("data_refreshed_at"),
        });
        actions.receiveDataRefreshedAt(resp.data);
      }
    }
  ),
  onLogout: actionOn(
    (__, { me }) => me.clear,
    (state, target) => {
      state.initialData = initialInitialData();
      state.initialDataReceived = false;
      state.initialDataLoading = false;
      state.data_refreshed_at = "";
    }
  ),
  receiveInitialData: action((state, payload) => {
    state.initialData = { ...state.initialData, ...payload };
  }),
  maybeHandleFetchInitialData: undefined,
  handleFetchInitialData: thunk(async (actions, payload, { getState }) => {
    const { INITIAL_DATA_ENDPOINT } = getState();

    await Promise.all([
      makeAuthorizedGetRequestToBackend({
        url: makeUrl(INITIAL_DATA_ENDPOINT),
      }).then((resp) => actions.receiveInitialData(resp.data)),
      // makeAuthorizedGetRequestToBackend({
      //   url: makeUrl("skykick_partners_inference_data")
      // }).then(resp => actions.receiveInitialData(resp.data)),
      // makeAuthorizedGetRequestToBackend({
      //   url: makeUrl("time_machine_data")
      // }).then(resp => actions.receiveTimeMachineData(resp.data))
    ]);
    actions.markInitialDataReceived();
  }),
  markInitialDataReceived: undefined,
  rowData: computed(
    [
      (state) => state.initialData.rowData,
      // @ts-ignore
      (state, storeState) => storeState.stars.starsSet,
    ],
    // rowData =>
    (rowData, starsSet) =>
      rowData.map((row) => ({
        ...row,
        id: row.dp__PartnerId,
        starred: starsSet && starsSet.has(row.dp__PartnerId) ? 1 : 0,
      }))
  ),
  partner2data: computed((state) => {
    const { rowData, inferenceData } = state.initialData;
    const obj = {};

    rowData.forEach((d) => {
      obj[d.dp__PartnerId] = { ...(obj[d.dp__PartnerId] || {}), ...d };
    });
    inferenceData.forEach((d) => {
      obj[d.dp__PartnerId] = { ...(obj[d.dp__PartnerId] || {}), ...d };
    });
    return obj;
  }),

  scoreHistoryDataByPartnerId: computed(
    [(s) => s.timeMachineData],
    (timeMachineData) => (partnerId) => {
      const partnerHistory = timeMachineData[partnerId];

      const ret = {}; //{ ...EMPTY_SCORE_HISTORY };

      if (partnerHistory) {
        const dates = partnerHistory["date"];
        const scores = partnerHistory["y"];

        _.zip(dates, scores).forEach(([date, score]) => {
          if (date >= SKYKICK_CUSTOMAX_LAUNCH_DATE) {
            ret[date] = score;
          }
        });
      }

      const data = _.sortBy(
        Object.entries(ret).map(([x, y]) => ({ x, y })),
        "x"
      );

      // console.log(data);
      return { id: partnerId, data };
    }
  ),

  // TODO: Ensure this is memoized
  averageChurnScore: computed(
    [(state) => state.initialData.rowData],
    (rowData) => {
      return _.sumBy(rowData, "y: ChurnRiskScore") / rowData.length;
    }
  ),
};

const SKYKICK_CUSTOMAX_LAUNCH_DATE = "2019-11-11";

function getAllScoreHistoryDates() {
  const start = moment(SKYKICK_CUSTOMAX_LAUNCH_DATE, "YYYY-MM-DD"); //.tz("America/Los_Angeles");
  const end = moment(); //.tz("America/Los_Angeles");
  const range = moment.range(start, end);
  return Array.from(range.by("day"))
    .map((m) => m.format("YYYY-MM-DD"))
    .filter(notGapDay);
}

function getArtificialScoreHistoryData() {
  let ret = {};
  getAllScoreHistoryDates().forEach((date) => {
    ret[date] = null;
  });
  return ret;
}

function notGapDay(m) {
  return !GAP_DAYS.includes(m);
}

const GAP_DAYS = [
  "2020-01-26",
  "2020-01-27",
  "2020-01-28",
  "2020-01-29",
  "2020-01-30",
  "2020-01-31",
  "2020-02-01",
  "2020-02-02",
  "2020-02-03",
  "2020-02-04",
  "2020-02-05",
  "2020-02-07",
  "2020-02-08",
  "2020-02-09",
  "2020-02-20",
  "2020-02-21",
  "2020-02-22",
  "2020-02-23",
  "2020-02-24",
  "2020-02-25", // Scores from this day were messed up!
  "2020-02-29",
  // "2020-03-01",
  "2020-03-02",
  "2020-03-06",
  "2020-03-07",
  "2020-03-08",
  "2020-03-09",
  "2020-03-10",
  "2020-03-11",
  "2020-03-12",
  "2020-03-13",
  "2020-03-14",
  "2020-03-15",
  "2020-03-16", // Scores from this day were messed up!
  "2020-03-26",
  "2020-03-27",
  "2020-03-28",
  "2020-03-29",
];
const EMPTY_SCORE_HISTORY = getArtificialScoreHistoryData();
// console.log(EMPTY_SCORE_HISTORY);

export default skykickModel;
