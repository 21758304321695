import { reducer, thunk, actionOn } from "easy-peasy";
import { fbReducer } from "../fbase";
import { getFirebase } from "react-redux-firebase";

const firebaseModel = {
  red: reducer(fbReducer),
  logout: thunk(async (actions, payload, { getState }) => {
    await getFirebase().logout();
  }),
  login: thunk(async (actions, payload, { getState }) => {
    const fb = getFirebase();

    const provider = new fb.auth.OAuthProvider("microsoft.com");
    provider.addScope("user.read");

    await fb.auth().setPersistence(fb.auth.Auth.Persistence.LOCAL);

    await fb.auth().signInWithRedirect(provider);
  }),
};

export { firebaseModel };
