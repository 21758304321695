import "styled-components/macro"; // DO NOT REMOVE. Necessary for using the css={`...`} prop
import { Icon } from "antd";
import React from "react";
import { MyCheckbox, MyCheckboxGroup } from "./styled-checkbox-group";
import COLORS from "../../styles/colors.js";
import _ from "lodash";
import { Card, Classes, Elevation, Icon as BP3Icon } from "@blueprintjs/core";

const Star = (
  <Icon
    css={`
      font-size: 1em;
      color: ${COLORS.mustard};
    `}
    type="star"
    theme="filled"
  />
);

const TakeAction = (
  <BP3Icon
    css={`
      font-size: 1em;
      color: #49aeef;
    `}
    icon="take-action"
    theme="filled"
  />
);

function accountForBlankStarColumnHeaderName(name, field) {
  if (field === "starred") {
    return Star;
  } else if (field === "action") {
    return TakeAction;
  } else {
    name = _.trim(name);
    return <span className={Classes.UI_TEXT}>{name}</span>;
  }
}

function VisibilityModal({ columns, showColumn, hideColumn }) {
  const shownColIds = columns.filter(col => col.visible).map(col => col.colId);
  const onChange = checkedList => {
    columns.forEach(col => {
      const toBeChecked =
        checkedList === null || checkedList.includes(col.colId);
      if (col.visible && !toBeChecked) {
        hideColumn(col.colId);
      } else if (!col.visible && toBeChecked) {
        showColumn(col.colId);
      }
    });
  };

  const checkboxChildren = columns.map(col => (
    <MyCheckbox
      key={col.colId}
      value={col.colId}
      title={accountForBlankStarColumnHeaderName(
        col.userProvidedColDef.headerName,
        col.userProvidedColDef.field
      )}
    />
  ));

  return (
    <VisibilityModalInner
      shownColIds={shownColIds}
      checkboxChildren={checkboxChildren}
      onChange={onChange}
    />
  );
}

function VisibilityModalInner({ shownColIds, checkboxChildren, onChange }) {
  return (
    <Card
      elevation={Elevation.FOUR}
      css={`
        border-radius: 4px !important;
        max-height: 100%;
        overflow: auto;
        & .ant-checkbox-group {
          background: unset !important;
          color: white !important;
        }
        & .ant-checkbox-wrapper {
          color: white !important;
        }
      `}
    >
      <h5 className={Classes.HEADING}>Show/Hide Columns</h5>
      <MyCheckboxGroup
        value={shownColIds}
        onChange={onChange}
        maxheight={"none"}
      >
        {checkboxChildren}
      </MyCheckboxGroup>
    </Card>
  );
}

export { VisibilityModal };
