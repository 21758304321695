import React from "react";
import "styled-components/macro";
import { NIVO_LINE_THEME } from "../AgTable/nivo-line-theme";
import _ from "lodash";
import { integerVF } from "../AgTable/gridOptions";
import { Classes, Tag } from "@blueprintjs/core";
import { ResponsiveSunburst } from "@nivo/sunburst";

export function calculateTotal(data) {
  if (_.isArray(data)) {
    return data.map(calculateTotal);
  } else if (_.isArray(data.children)) {
    return {
      id: data.id,
      total: _.sumBy(calculateTotal(data.children), "total")
    };
  } else {
    return { id: data.id, total: data.value };
  }
}
export function NivoSunburst({ width, height, data }) {
  const totals = calculateTotal(data.children);
  const formatSliceLabel = ({ value }) => {
    // return `${pct}%`;
    return `${integerVF(value)}`;
    // return `${integerVF(value)} (${pct}%)`;
  };
  const margin = { top: 40, right: 150, bottom: 40, left: 150 };
  return (
    <div
      css={`
        text-align: center;
        position: relative;
        width: ${width}px;
        height: ${height}px;
      `}
    >
      <ResponsiveSunburst
        // width={width}
        // height={height}
        data={data}
        theme={NIVO_LINE_THEME}
        margin={margin}
        innerRadius={0.5}
        // startAngle={90}
        // endAngle={360 + 90}
        padAngle={2}
        cornerRadius={0}
        colors={{ scheme: "category10" }}
        borderWidth={1}
        radialLabelsSkipAngle={10}
        radialLabelsTextXOffset={6}
        radialLabelsTextColor="#fff"
        radialLabelsLinkOffset={0}
        radialLabelsLinkDiagonalLength={16}
        radialLabelsLinkHorizontalLength={24}
        radialLabelsLinkStrokeWidth={1}
        radialLabelsLinkColor={{ from: "color" }}
        slicesLabelsSkipAngle={10}
        slicesLabelsTextColor="#fff"
        sliceLabel={formatSliceLabel}
        animate={true}
        motionStiffness={90}
        motionDamping={15}
        tooltip={tooltipRendererFactory(totals)}
        // sortByValue={true}
        // legends={LEGENDS}
      />
      <div
        css={`
          position: absolute;
          top: 0;
          right: ${margin.right}px;
          bottom: 0;
          left: ${margin.left}px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          font-size: 16px;
          color: #ffffff;
          // background: #FFFFFF33
          text-align: center;
          // This is important to preserve the chart interactivity
          pointer-events: none;
        `}
      >
        <span>{integerVF(_.sumBy(totals, "total"))}</span>
      </div>
    </div>
  );
}

function tooltipRendererFactory(totals) {
  return ({ id, value }) => {
    const pct = Math.round(
      (100 * value) / totals.find(el => el.id === id).total
    );
    return (
      <Tag
        large
        className={`${Classes.ELEVATION_1}`}
        css={`
          color: #fff !important;
          background-color: rgba(0, 25, 43, 0.8) !important;
        `}
      >
        {`${pct}%`}
      </Tag>
    );
  };
}

const LEGENDS = [
  {
    anchor: "bottom",
    direction: "row",
    translateY: 56,
    itemWidth: 100,
    itemHeight: 18,
    itemTextColor: "#999",
    symbolSize: 18,
    symbolShape: "circle",
    effects: [
      {
        on: "hover",
        style: {
          itemTextColor: "#000"
        }
      }
    ]
  }
];
