import _ from "lodash";

export function getSegmentId(seg) {
  return [seg.t0, seg.t1].filter(v => !!v).join("__");
}

export function getSegmentName(seg) {
  const segid = _.isString(seg) ? seg : getSegmentId(seg);
  return SEG_NAMES[segid];
}

export function getAxisName(axis) {
  let axisid = _.isString(axis) ? axis : axis.id;
  if (axisid === "") {
    axisid = "none";
  }
  return AXE_NAMES[axisid];
}

export const isRealAxis = axis => axis.id !== "none";
export const AXE_NAMES = {
  none: "None",
  segment: "Segment",
  region: "Region",
  subregion: "Sub-Region",
  usd_usage: "USD Usage",
  distrib_usage: "Distrib Usage",
  mgmt_status: "Mgmt Status",
  tam_owner: "TAM Owner",
  sales_owner: "Sales Owner"
};
export const SEG_NAMES = {
  partners: "Partners",
  partners__np: "No-Pay Partners",
  players: "Payers",
  players__al: "Recent Payers",
  players__td: "Current Payers",
  players__nc_td: "No-Churn Payers",
  churners: "Churners",
  churners__al: "Recent Churners",
  churners__nw: "Perma-Churners",
  churners__al_nw: "Recent Perma-Churners",
  newbies__al: "Beginners"
};
export const SEGMENT_HIERARCHY = [
  {
    parent: { t0: "partners" },
    children: [
      { t0: "partners", t1: "np" },
      { t0: "players" },
      { t0: "players", t1: "al" },
      { t0: "players", t1: "td" },
      { t0: "players", t1: "nc_td" },
      { t0: "churners" },
      { t0: "churners", t1: "al" },
      { t0: "churners", t1: "nw" },
      { t0: "churners", t1: "al_nw" },
      { t0: "newbies", t1: "al" }
    ]
  },
  {
    parent: { t0: "players" },
    children: [
      { t0: "players", t1: "al" },
      { t0: "players", t1: "td" },
      { t0: "players", t1: "nc_td" },
      { t0: "churners" },
      { t0: "churners", t1: "al" },
      { t0: "churners", t1: "nw" },
      { t0: "churners", t1: "al_nw" },
      { t0: "newbies", t1: "al" }
    ]
  },
  {
    parent: { t0: "players", t1: "al" },
    children: [{ t0: "newbies", t1: "al" }]
  },
  {
    parent: { t0: "players", t1: "td" },
    children: [{ t0: "players", t1: "nc_td" }]
  },
  {
    parent: { t0: "churners" },
    children: [
      { t0: "churners", t1: "al" },
      { t0: "churners", t1: "nw" },
      { t0: "churners", t1: "al_nw" }
    ]
  },
  {
    parent: { t0: "churners", t1: "al" },
    children: [{ t0: "churners", t1: "al_nw" }]
  },
  {
    parent: { t0: "churners", t1: "nw" },
    children: [{ t0: "churners", t1: "al_nw" }]
  }
];

export function giveNames({ seg1, seg2, axis, ...rest }) {
  return {
    seg1: seg1.name ? seg1 : { ...seg1, name: getSegmentName(seg1) },
    seg2: seg2.name ? seg2 : { ...seg2, name: getSegmentName(seg2) },
    axis: axis.name ? axis : { ...axis, name: getAxisName(axis) },
    ...rest
  };
}

export function giveSize(t) {
  return { width: 600, height: 400, ...t };
}

export const NULL_AXIS = { id: "none" };
export const DEFAULT_TILES = [
  {
    seg1: { t0: "partners" },
    seg2: { t0: "players" }
  },
  {
    seg1: { t0: "players" },
    seg2: { t0: "churners" }
  },
  {
    seg1: { t0: "players" },
    seg2: { t0: "players", t1: "nc_td" }
  },
  {
    seg1: { t0: "players" },
    seg2: { t0: "players", t1: "td" }
  },
  {
    seg1: { t0: "players" },
    seg2: { t0: "churners", t1: "nw" }
  },
  {
    seg1: { t0: "players" },
    seg2: { t0: "churners", t1: "al" }
  },
  {
    axis: { id: "distrib_usage" },
    seg1: {
      t0: "players",
      t1: "nc_td"
    },
    seg2: { t0: "churners" }
  },
  {
    axis: { id: "distrib_usage" },
    seg1: {
      t0: "players",
      t1: "nc_td"
    },
    seg2: { t0: "churners", t1: "nw" }
  },
  {
    axis: { id: "usd_usage" },
    seg1: {
      t0: "players",
      t1: "nc_td"
    },
    seg2: { t0: "churners" }
  },
  {
    axis: { id: "usd_usage" },
    seg1: {
      t0: "players",
      t1: "nc_td"
    },
    seg2: { t0: "churners", t1: "nw" }
  },
  {
    axis: { id: "region" },
    seg1: {
      t0: "players",
      t1: "nc_td"
    },
    seg2: { t0: "churners", t1: "nw" }
  },
  {
    axis: { id: "segment" },
    seg1: {
      t0: "players",
      t1: "nc_td"
    },
    seg2: { t0: "churners", t1: "nw" },
    ignoreNA: true
  },
  {
    axis: { id: "mgmt_status" },
    seg1: {
      t0: "players",
      t1: "nc_td"
    },
    seg2: { t0: "churners", t1: "nw" },
    ignoreNA: true
  },
  {
    axis: { id: "tam_owner" },
    seg1: {
      t0: "players",
      t1: "nc_td"
    },
    seg2: { t0: "churners", t1: "nw" },
    ignoreNA: true,
    normalizeBarHeights: true
  },
  {
    axis: { id: "sales_owner" },
    seg1: {
      t0: "players",
      t1: "nc_td"
    },
    seg2: { t0: "churners", t1: "nw" },
    normalizeBarHeights: true
  }
]
  .map((t, id) => ({ id, axis: t.axis ?? NULL_AXIS, ...t }))
  .map(giveNames)
  .map(giveSize);
