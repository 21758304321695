import { AutoComplete, Input } from "antd";
import React, { useCallback, useState } from "react";
import styled from "styled-components/macro"; // DO NOT REMOVE. Necessary for using the css={`...`} prop
import { useBoolean } from "../../helpers/useBoolean";
import { useOptions } from "./search-box-ac-options";
import { useSearchBoxText, useSetSearchBoxText } from "./search-box-store";

const { Search } = Input;

const StyledAutoComplete = styled(AutoComplete)`
  &&& {
    width: 200px;
    transition: width 0.2s ease-in-out;
  }
  &&&.ant-select-focused {
    width: 400px !important;
  }
`;

export function RbnSearchBox({ rowData, onSearch }) {
  const value = useSearchBoxText();
  const setText = useSetSearchBoxText();
  const dataSource = useOptions(rowData, value);

  // #EscapeKeyToToggleAutoComplete
  // const isOpen = useBoolean(true);
  // const onKeyUp = ev => {
  //   if (ev.keyCode === 27) {
  //     // Escape key
  //     ev.preventDefault();
  //     if (dataSource.length) {
  //       isOpen.toggle();
  //     }
  //   }
  // };

  return (
    <StyledAutoComplete
      value={value}
      dataSource={dataSource}
      onSelect={onSearch}
      onChange={setText}
      defaultActiveFirstOption={false}
      placeholder="Search"
      // #EscapeKeyToToggleAutoComplete
      // open={isOpen.value && dataSource.length}
      // onFocus={isOpen.setTrue}
      // onBlur={isOpen.setFalse}
    >
      <Search
        allowClear={true}
        onSearch={onSearch}
        // #EscapeKeyToToggleAutoComplete
        // onKeyUp={onKeyUp}
      />
    </StyledAutoComplete>
  );
}
