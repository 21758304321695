import { useEffect } from "react";
import smartlookClient from "smartlook-client";
import { useBoolean } from "../helpers/useBoolean";

const PROJECT_KEY = "23f41bceabe76ff89f8bec9659d7aff4df4fa959";

export function useSmartlook(meData) {
  useInit();
  useIdentify(meData);
}

function useInit() {
  useEffect(() => {
    try {
      smartlookClient.init(PROJECT_KEY);
    } catch (e) {
      console.log("Failed smartlook init");
    }
  }, []);
}

function useIdentify(meData) {
  const identified = useBoolean(false);
  const myEmail = meData?.email ?? null;

  useEffect(() => {
    if (myEmail && !identified.value) {
      try {
        smartlookClient.identify(myEmail, meData);
        identified.setTrue();
      } catch (e) {
        console.log("Failed smartlook identify");
      }
    }
  }, [myEmail]);
}
