import React from "react";
import {
  Classes,
  Popover,
  PopoverInteractionKind,
  Position
} from "@blueprintjs/core";
import { ThePopoverContent } from "./popover-content";
import { ThePopoverTarget } from "./popover-target";
import "styled-components/macro";
import _ from "lodash";

export function NapoCellRenderer({
  value: napo,
  data: { dp__PartnerId: partnerId }
}) {
  return <ThePopover napo={napo} partnerId={partnerId} />;
}

function ThePopover({ napo, partnerId }) {
  if (_.isUndefined(napo) || _.isNull(napo) || _.isNaN(napo)) {
    return <ThePopoverTarget napo={napo} />;
  } else {
    return (
      <div
        css={`
          display: flex;
          justify-content: flex-end;
        `}
      >
        <Popover
          content={<ThePopoverContent partnerId={partnerId} />}
          position={Position.RIGHT_TOP}
          interactionKind={PopoverInteractionKind.HOVER}
          popoverClassName={`${Classes.ELEVATION_4}`}
          hoverOpenDelay={300}
          fill={false}
        >
          <ThePopoverTarget napo={napo} />
        </Popover>
      </div>
    );
  }
}
