import React from "react";
import "styled-components/macro";
import { Callout, H4, H6 } from "@blueprintjs/core";

const DOC_ITEMS = [
  {
    title: "Campaign #1",
    content: (
      <div>
        <H6>Goal</H6>
        <p>{`Reduce churn among high-value, high-risk partners`}</p>
        <H6>Criteria</H6>
        <ol>
          <li>{`At least 20% risk of churning within 3 months`}</li>
          <li>{`No TAM owner currently assigned`}</li>
          <li>{`At least $1K recognized revenue in past year`}</li>
        </ol>
        <H6>Motivation</H6>
        <ol>
          <li>{`Target partners at high risk of churning`}</li>
          <li>{`Assume TAM owners can handle solo`}</li>
          <li>{`Focus on high-value partners`}</li>
        </ol>
      </div>
    ),
  },
  {
    title: "Churn",
    content:
      "When a partner goes from at least one Paying Order on day X to zero Paying Orders on day X+1. That is, churn is an event resulting from a decrease (to zero) of Paying Orders from one day to the next.",
  },
  {
    title: "Permachurn",
    content:
      "A Backup customer who has churned and never returned to using Backup.",
  },
  {
    title: "Scored Partner",
    content:
      "A partner that has been given at least one daily Customax churn score.",
  },
  { title: "Retained Partner", content: "A partner that has not churned." },
  {
    title: "Won-Back",
    content: "When a partner churned and then became active again.",
  },
  {
    title: "Score",
    content:
      "The probability of a partner churning within three months of the scoring date. Active partners are scored daily.",
  },
  {
    title: "Paying Order",
    content:
      "An order that is associated with an active backup service that is still being charged to the customer. Also known as an 'Active Order'.",
  },

  // {
  //   title: "Campaign #1",
  //   content: `Membership: All partners with "Bumpy" or "Hill" Shapes and with at least a 15% 3-Month Churn Risk Score. Goal: Prevent these partners from churning and help them increase their order counts to bring down their churn scores.`,
  // },
  // {
  //   title: "Campaign #2",
  //   content: `Membership: The top two quartiles by LTV of partners with "Bumpy" or "Hill" Shapes and with at least a 15% 3-Month Churn Risk Score. Goal: Prevent these partners from churning and help them increase their order counts to bring down their churn scores.`,
  // },
  // {
  //   title: "Campaign #3",
  //   content: `Membership: The top two quartiles by LTV of partners with "Plateau" Shapes. Goal: Help these partners start growing their order counts again.`,
  // },
];

export function ManualPage() {
  return (
    <div
      css={`
        width: 100%;
        overflow-y: scroll;
        & .doc-item {
          margin-top: 24px;
        }
        & .doc-item:first-child {
          margin-top: 0;
        }
      `}
    >
      {DOC_ITEMS.map(({ title, content }) => (
        <DocItem key={title} title={title} content={content} />
      ))}
    </div>
  );
}

function DocItem({ title, content }) {
  return (
    <Callout className={"doc-item"}>
      <H4>{title}</H4>
      {content}
    </Callout>
  );
}
