import { Thunk } from "easy-peasy";
import { actions } from "./commonActions";
import { thunks } from "./commonThunks";
import { firebaseModel } from "./firebaseModel";
import meModel, { MeModel } from "./meModel";
import skykickModel, { SkykickModel } from "./skykickModel";
import { starsModel } from "./starsModel";
import miscModel, { ModelMisc } from "./model-misc";

export interface Injections {}

export interface FirebaseModel {
  red: any;
  login: Thunk<FirebaseModel, void, Injections, StoreModel>;
  logout: Thunk<FirebaseModel, void, Injections, StoreModel>;
}

// The interface representing our entire store model
export interface StoreModel {
  me: MeModel;
  skykick: SkykickModel;
  firebase: FirebaseModel;
  stars: any;
  misc: ModelMisc;
}

const model: StoreModel = {
  me: meModel,
  skykick: skykickModel,
  firebase: firebaseModel,
  stars: starsModel,
  misc: miscModel
};

function isResourceModel(modelSlice) {
  return !!modelSlice.INITIAL_DATA_ENDPOINT;
}

Object.entries(model).forEach(([modelSliceName, modelSlice]) => {
  if (!isResourceModel(modelSlice)) {
    return;
  }

  modelSlice.initialDataReceived = false;
  modelSlice.initialDataLoading = false;

  const myActions = actions();
  [
    "markInitialDataReceived",
    "markInitialDataNotReceived",
    "markInitialDataLoading",
    "markInitialDataNotLoading",
    "receiveInitialData",
    "clear"
  ].forEach(actionName => {
    if (!modelSlice[actionName]) {
      modelSlice[actionName] = myActions[actionName];
    }
  });

  const myThunks = thunks(modelSliceName);
  ["handleFetchInitialData", "maybeHandleFetchInitialData"].forEach(
    thunkName => {
      if (!modelSlice[thunkName]) {
        modelSlice[thunkName] = myThunks[thunkName];
      }
    }
  );
});

export { model };
