import React, { useState } from "react";
import styled from "styled-components/macro";
import { H4, NonIdealState, Tab, Tabs } from "@blueprintjs/core";
import { ScoreFactors } from "./score-factors";
import { useStoreState } from "../../../hooks/ep";
import { isValidChurnScore } from "./validate-churn-score";
import { getXAxisDates, NivoTimeSeriesLine } from "./churn-score-nivo-line";
import moment from "moment";
import { percentageVF } from "../gridOptions";

const PopConWrapper = styled.div`
  padding: 13px 24px 23px 24px;
  //height: 500px;
  height: 440px;
  width: 500px;
  overflow-y: auto;
`;

export const PopConHeader = styled(H4)`
  margin: 0;
`;

const TAB_IDS = {
  scoreFactors: "score-factors",
  scoreHistory: "score-history",
};

export function ChuScoPopoverContent({ churnScore, partnerId }) {
  const defaultTabId = getDefaultTabId(churnScore);
  const [selectedTabId, setSelectedTabId] = useState(defaultTabId);
  const handleTabChange = (newTabId, prevTabId, event) => {
    setSelectedTabId(newTabId);
  };
  return (
    <PopConWrapper>
      <Tabs
        onChange={handleTabChange}
        selectedTabId={selectedTabId}
        large
        renderActiveTabPanelOnly={true}
      >
        {isValidChurnScore(churnScore) && (
          <Tab
            id={TAB_IDS.scoreFactors}
            title="Factors"
            panel={<ScoreFactors partnerId={partnerId} />}
          />
        )}
        <Tab
          id={TAB_IDS.scoreHistory}
          title="History"
          panel={<ScoreHistory partnerId={partnerId} />}
        />
      </Tabs>
    </PopConWrapper>
  );
}

function getDefaultTabId(churnScore) {
  return isValidChurnScore(churnScore)
    ? TAB_IDS.scoreFactors
    : TAB_IDS.scoreHistory;
}

function useScoreHistoryData(partnerId) {
  return useStoreState(
    (s) => s.skykick.scoreHistoryDataByPartnerId(partnerId),
    [partnerId]
  );
}

export function ScoreHistory({ partnerId }) {
  const partnerData = useScoreHistoryData(partnerId);
  if (partnerData.data.length < 2) {
    const description =
      partnerData.data.length === 0
        ? `Partner has never been scored`
        : `Current score of ${percentageVF({
            value: partnerData.data[0].y,
          })} from ${moment(partnerData.data[0].x).format(
            "M/D"
          )} is partner's only score`;
    return (
      <NonIdealState
        icon="info-sign"
        title="History Plot Unavailable"
        description={description}
      />
    );
  }
  const dates = partnerData.data.map(({ x }) => x);

  const data = [partnerData];
  const yMin = 0;
  const yMax = 50;
  const yTicks = [0, 10, 20, 30, 40, 50];
  const yTitle = "3 Month Churn Risk";
  const xTicks = getXAxisDates(dates, 7);
  const xTitle = "Date of Prediction";
  const height = 338;
  const width = 450;
  function TooltipText({ x, y }) {
    return `${y}% on ${moment(x).format("M/D")}`;
  }

  console.log({
    data,
    yMin,
    yMax,
    yTicks,
    yTitle,
    xTicks,
    xTitle,
    height,
    width,
  });

  return (
    <NivoTimeSeriesLine
      data={data}
      yMin={yMin}
      yMax={yMax}
      yTicks={yTicks}
      yTitle={yTitle}
      xTicks={xTicks}
      xTitle={xTitle}
      height={height}
      width={width}
      TooltipText={TooltipText}
    />
  );
}
