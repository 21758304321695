import React from "react";
import routes from "./helpers/routes";
import { Redirect, Route } from "react-router";
import AppWrapper from "./components/AppWrapper";
import { useIsAuthorized } from "./helpers/useAuthorization";
import { BriefSkykickTable } from "./components/BriefSkykickTable";
import useRedirect from "./hooks/useRedirect";
import { SignInForm } from "./components/SignInForm";
import { NapoPage } from "./napo";
import { RollupPage } from "./components/rollup/RollupPage";
import { PulsePage } from "./components/PulsePage";
import { ManualPage } from "./components/ManualPage";

function MyRoute({ route, Component, protect, leftNav }) {
  let WrappedComponent;
  if (protect) {
    WrappedComponent = (props) => {
      const auth = useIsAuthorized(route);
      const doneLoading = !auth.loading;
      const isDefinitelyNotAuthorized = doneLoading && !auth.isAuthorized;
      const isDefinitelyAuthorized = doneLoading && auth.isAuthorized;
      useRedirect(() => isDefinitelyNotAuthorized, routes.signin);
      return isDefinitelyAuthorized ? (
        <AppWrapper hideLeftNav={!leftNav}>
          <Component {...props} />
        </AppWrapper>
      ) : null;
    };
  } else {
    WrappedComponent = (props) => (
      <AppWrapper hideLeftNav={!leftNav}>
        <Component {...props} />
      </AppWrapper>
    );
  }
  return <Route exact path={route} component={WrappedComponent} key={route} />;
}

export const AllRoutes = [
  MyRoute({
    route: routes.signin,
    Component: SignInForm,
    protect: false,
    leftNav: false,
  }),
  MyRoute({
    route: routes.dashboard,
    Component: BriefSkykickTable,
    protect: true,
    leftNav: true,
  }),
  MyRoute({
    route: routes.napo,
    Component: NapoPage,
    protect: true,
    leftNav: true,
  }),
  MyRoute({
    route: routes.rollup,
    Component: RollupPage,
    protect: true,
    leftNav: true,
  }),
  MyRoute({
    route: routes.pulse,
    Component: PulsePage,
    protect: true,
    leftNav: true,
  }),
  MyRoute({
    route: routes.manual,
    Component: ManualPage,
    protect: true,
    leftNav: true,
  }),
  <Route render={() => <Redirect to={routes.DEFAULT} />} key="default_route" />,
];
