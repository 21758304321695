import { useStoreActions } from "../hooks/ep";
import { useAsync } from "react-use";
import { useEndpoint } from "./use-endpoint";

export function useEnsurePartnersData() {
  const handleSkykick = useStoreActions(
    (a) => a.skykick.maybeHandleFetchInitialData
  );
  const { loading: loadingSkykick } = useAsync(handleSkykick);
  const handleStars = useStoreActions((a) => a.stars.handleFetchInitialData);
  const { loading: loadingStars } = useAsync(handleStars);

  return loadingSkykick || loadingStars;
}

export function useEnsureScoresAndShapesData() {
  return useEndpoint("/scores-and-shapes");
}
