import _ from "lodash";
import { integerVF } from "../components/AgTable/gridOptions";

const FEAT_CONTRIB_FIELD_PATT = /^C:\s(?<featname>.+)$/;
const FEAT_VALUE_FIELD_PATT = /^X:\s(?<featname>.+)$/;
const SCORE_INFO_FIELD_PATT = /^[CX]:\s(?<featname>.+)$/;

function getFieldType(field) {
  if (FEAT_CONTRIB_FIELD_PATT.exec(field)) {
    return "contrib";
  } else if (FEAT_VALUE_FIELD_PATT.exec(field)) {
    return "value";
  } else {
    return null;
  }
}

function getFeatureName(field) {
  return SCORE_INFO_FIELD_PATT.exec(field).groups.featname;
}

export const Features = {
  other_factors: {
    full_name: "<<OTHER>>",
    human_name: "Other Factors",
  },
  n_active_orders: {
    full_name: "LAST(PartnerMonthlySummary.SUM(fbms__ActiveOrder))",
    human_name: "Number of Active Orders",
  },
  n_open_migration_orders: {
    full_name: "LAST(FPDS.OpenMigrationOrders)",
    human_name: "Number of Open Migration Orders",
  },
  n_active_exchange_subscriptions: {
    full_name: "LAST(PartnerMonthlySummary.SUM(fbms__ExchangeSubscription))",
    human_name: "Number of Active Exchange Subscriptions",
  },
  n_active_sharepoint_subscriptions: {
    full_name: "LAST(PartnerMonthlySummary.SUM(fbms__SharePointSubscription))",
    human_name: "Number of Active SharePoint Subscriptions",
  },
  n_consec_active_months_normalized_by_backup_months: {
    full_name:
      "LAST(PartnerMonthlySummary.fbms__ConsecutivePreviousMonthsActive) / TIME_SINCE(BackupPlacedActivationDate, unit=days) / 30",
    human_name: "Consecutive Active Months / Total Backup Months",
  },
  n_consec_active_months_normalized_by_registered_months: {
    full_name:
      "LAST(PartnerMonthlySummary.fbms__ConsecutivePreviousMonthsActive) / TIME_SINCE(PartnerCreatedOnDate, unit=days) / 30",
    human_name: "Consecutive Active Months / Total Registered Months ",
  },
  past_month_phonecall_workeffort_normalized: {
    full_name:
      "SUM_PAST_MONTH(CRM__PhoneCall.cv_workeffortinseconds, modifiedon) / SUM(CRM__PhoneCall.cv_workeffortinseconds) / TIME_SINCE(PartnerCreatedOnDate, unit=days) / 30",
    human_name: "Measure of Recent Phone Call Engagement",
  },
  n_consec_active_months: {
    full_name:
      "LAST(PartnerMonthlySummary.fbms__ConsecutivePreviousMonthsActive)",
    human_name: "Number of Consecutive Active Months",
  },
  trend_exchange_licenses: {
    full_name:
      "TREND(PartnerMonthlySummary.SUM(fbms__ExchangeLicenses), MyTimeIndex)",
    // TODO: Should trend only be for past 3 months?
    human_name: "Average Monthly Increase of Exchange Licenses",
  },
  trend_sharepoint_licenses: {
    full_name:
      "TREND(PartnerMonthlySummary.SUM(fbms__SharePointLicenses), MyTimeIndex)",
    human_name: "Average Monthly Increase of SharePoint Licenses",
  },
  trend_licenses: {
    full_name: "TREND(PartnerMonthlySummary.SUM(fbms__Licenses), MyTimeIndex)",
    human_name: "Average Monthly Increase of Backup Licenses",
  },
  trend_seats: {
    full_name: "TREND(PartnerMonthlySummary.SUM(fbms__Seats), MyTimeIndex)",
    human_name: "Average Monthly Increase of Backup Seats",
  },
  trend_migration_licenses: {
    full_name: "TREND(FPDS.PlacedMigrationLicenses, Date)",
    human_name: "Average Daily Increase of Placed Migration Licenses",
  },
  days_since_last_login: {
    full_name: "TIME_SINCE(LastLoginDate, unit=days)",
    human_name: "Number of Days Since Partner Last Logged In",
  },
};

const FULL_NAME__TO__HUMAN_NAME = Object.values(Features).reduce(
  (prev, curr) => ({
    ...prev,
    [curr.full_name]: curr.human_name,
  }),
  {}
);

export function explainFeatureValue({ name, value }) {
  if (_.isUndefined(value) || _.isNull(value)) {
    return null;
  } else if (name === Features.other_factors.full_name) {
    // return null; // Reverted @ 2020-07-07T12:05:13 (PDT)
    // return "(contribution from other factors)"; // Commented out @ 2020-07-06T21:35:24 (PDT)
    if (value < 0) {
      return "Positive recent Paying Orders trends";
    } else {
      return "Negative recent Paying Orders trends";
    }
  } else if (name === Features.n_active_orders.full_name) {
    if (value === 1) {
      return "1 active order";
    } else if (value === 0) {
      return null;
    } else {
      return null;
      // return `${integerVF(value)} active orders`;  // Commented out @ 2020-07-07T12:05:13 (PDT)
    }
  } else if (name === Features.n_open_migration_orders.full_name) {
    if (value === 1) {
      return "1 open Migration order";
    } else {
      return null;
      // return `${integerVF(value)} open Migration orders`;  // Commented out @ 2020-07-07T12:05:13 (PDT)
    }
  } else if (name === Features.n_active_exchange_subscriptions.full_name) {
    if (value === 1) {
      return "1 active Exchange subscription";
    } else {
      return null;
      // return `${integerVF(value)} active Exchange subscriptions`;  // Commented out @ 2020-07-07T12:05:13 (PDT)
    }
  } else if (name === Features.n_active_sharepoint_subscriptions.full_name) {
    if (value === 1) {
      return "1 active SharePoint subscription";
    } else {
      return null;
      // return `${integerVF(value)} active SharePoint subscriptions`;  // Commented out @ 2020-07-07T12:05:13 (PDT)
    }
  } else if (name === Features.n_consec_active_months.full_name) {
    if (value === 1) {
      return "1 consecutive active month";
    } else if (value === 0) {
      return null;
    } else {
      return `${integerVF(value)} consecutive active months`;
    }
    // } else if (
    //   name ===
    //   Features.n_consec_active_months_normalized_by_backup_months.full_name
    // ) {
    //   if (value <= 0.5) {
    //     return "Recent active streak comprises minority of Backup tenure";
    //   } else {
    //     return "Recent active streak comprises majority of Backup tenure";
    //   }
    // } else if (
    //   name ===
    //   Features.n_consec_active_months_normalized_by_registered_months.full_name
    // ) {
    //   if (value <= 0.5) {
    //     return "Recent active streak comprises minority of SkyKick tenure";
    //   } else {
    //     return "Recent active streak comprises majority of SkyKick tenure";
    //   }
  } else if (
    name === Features.past_month_phonecall_workeffort_normalized.full_name
  ) {
    if (value <= 0.2) {
      return "Unusually low recent phone engagement";
    } else if (value >= 0.8) {
      return "Unusually high recent phone engagement";
    } else {
      return "Normal recent phone engagement";
    }
  } else if (name === Features.trend_exchange_licenses.full_name) {
    if (!value) {
      return "No change in # (Backup) Exchange licenses";
    } else if (value < 0) {
      return "# (Backup) Exchange licenses trending downwards";
    } else {
      return "# (Backup) Exchange licenses trending upwards";
    }
    // } else if (name === Features.trend_sharepoint_licenses.full_name) {
    //   if (value <= 0) {
    //     return "# SharePoint Backup licenses decreasing over time";
    //   } else {
    //     return "# SharePoint Backup licenses increasing over time";
    //   }
  } else if (name === Features.trend_licenses.full_name) {
    if (!value) {
      return "No change in # (Backup) licenses";
    } else if (value < 0) {
      return "# (Backup) licenses trending downwards";
    } else {
      return "# (Backup) licenses trending upwards";
    }
  } else if (name === Features.trend_seats.full_name) {
    if (!value) {
      return "No change in # (Backup) seats";
    } else if (value < 0) {
      return "# (Backup) seats trending downwards";
    } else {
      return "# (Backup) seats trending upwards";
    }
  } else if (name === Features.trend_migration_licenses.full_name) {
    if (!value) {
      return "No change in # Migration licenses";
    } else if (value < 0) {
      return "# Migration licenses trending downwards";
    } else {
      return "# Migration licenses trending upwards";
    }
  } else if (name === Features.days_since_last_login.full_name) {
    if (!value) {
      return "Last login date is unknown";
      // } else if (value === 1) {
      //   return "1 day since last login";
    } else if (value < 90) {
      return "Recent login";
    } else {
      return "No recent logins";
      // return `${integerVF(value)} days since last login`;
    }
  }
}

export function getScoreInfo(partnerRow) {
  const featureRows = {};
  Object.entries(partnerRow)
    .filter(([field]) => !!getFieldType(field))
    .map(([field, featureValue]) => ({
      featureName: getFeatureName(field),
      fieldType: getFieldType(field),
      featureValue,
    }))
    .forEach(({ featureName, fieldType, featureValue }) => {
      featureRows[featureName] = {
        [fieldType]: featureValue,
        ...(featureRows[featureName] || {}),
      };
    });

  let scoreInfo = Object.entries(featureRows).map(
    ([name, { contrib, value }]) => ({
      name,
      humanName: FULL_NAME__TO__HUMAN_NAME[name],
      value,
      contrib,
      valueExplanation: explainFeatureValue({ name, value }),
    })
  );
  scoreInfo = scoreInfo.filter((si) => Math.abs(si.contrib) > 0);
  return _.sortBy(scoreInfo, ({ contrib }) => -Math.abs(contrib));
}
