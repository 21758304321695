import { makeAuthorizedGetRequestToBackend, makeUrl } from "./backendApi";
import { useAsync } from "react-use";

export function useEndpoint(endpoint) {
  const fn = async () => {
    const { data } = await makeAuthorizedGetRequestToBackend({
      url: makeUrl(endpoint),
    });
    return data;
  };
  const { loading, value: data } = useAsync(fn, [endpoint]);
  return { loading, data };
}
