import React, { useMemo, useState } from "react";
import useAuthentication from "../hooks/useAuthentication";
import useRedirect from "../hooks/useRedirect";
import { Button, Col, message, Row } from "antd";
import routes from "../helpers/routes";
import { useStoreActions } from "easy-peasy";
import { useFirebaseState } from "../fbase/useFirebaseState";
import versionedLocalStorageKey from "../helpers/versionedLocalStorageKey";
import "styled-components/macro"; // DO NOT REMOVE. Necessary for using the css={`...`} prop
import { Callout } from "@blueprintjs/core";

export function SignInForm() {
  const { authUser } = useAuthentication();
  const handleLogin = useStoreActions((a) => a.firebase.login);
  const { numVisitsKey, numVisits } = useNumVisits();
  const [btnDisabled, setBtnDisabled] = useState(false);
  const firebaseAuthIsReady = useAuthIsReady();

  // Redirect if user already logged in
  useRedirect(() => !!authUser, routes.dashboard, [authUser]);

  const onSubmit = async (event) => {
    event.preventDefault();
    setBtnDisabled(true);
    window.localStorage.setItem(numVisitsKey, (numVisits + 1).toString());
    try {
      await handleLogin();
    } catch (err) {
      message.error(err.message);
      setBtnDisabled(false);
    }
  };

  return !firebaseAuthIsReady ? (
    <p>Initializing Firebase instance...</p>
  ) : (
    <>
      <form onSubmit={onSubmit}>
        <Row
          type="flex"
          justify="center"
          css={`
            margin-top: 32px;
          `}
        >
          <Col>
            <Button
              data-testid="signin_submit_button"
              disabled={btnDisabled}
              type="primary"
              htmlType="submit"
              size="small"
            >
              Sign In
            </Button>
          </Col>
        </Row>
        <Row
          type="flex"
          justify="center"
          css={`
            margin-top: 20px;
          `}
        >
          <Col xs={12}>
            <HelpText numVisits={numVisits} />
          </Col>
        </Row>
      </form>
    </>
  );
}

function useNumVisits() {
  const numVisitsKey = versionedLocalStorageKey("login-page-number-visits");
  const numVisits = parseInt(
    window.localStorage.getItem(numVisitsKey) || "0",
    10
  );
  return { numVisitsKey, numVisits };
}

function useAuthIsReady() {
  const fbState = useFirebaseState();
  // noinspection JSUnresolvedVariable
  return !fbState.isInitializing && fbState.auth.isLoaded;
}

function HelpText({ numVisits }) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => getHelpText(numVisits), []);
}

function getHelpText(numVisits) {
  if (numVisits === 0) {
    return <span> </span>;
  } else {
    return (
      <Callout
        intent="warning"
        title="Trouble signing in?"
        css={`
          margin-top: 48px;
        `}
      >
        Please make sure you are using a valid @skykick.com email to
        authenticate via Azure AD. If this issue persists, please email
        team@saxecap.com for additional assistance.
      </Callout>
    );
  }
}
