import { useStoreState } from "../../hooks/ep";
import { dateVF } from "../AgTable/gridOptions";
import { record2mailto } from "../../helpers/emailTemplates";
import React from "react";
import { Button } from "@blueprintjs/core";

function useSignedInUserDisplayName() {
  return useStoreState(s => s.me.fullName);
}

const NO_ACTION_REQUIRED = "No Action Required";

export function useRecommendedActionMailtoLink(data) {
  const { customer_contact_full_name, recommended_action } = data;

  const [
    customer_contact_first_name,
    customer_contact_last_name
  ] = customer_contact_full_name.split(" ");
  const current_user_full_name = useSignedInUserDisplayName();
  const record = {
    customer_contact_first_name,
    customer_contact_last_name,
    current_user_full_name,
    ...data
  };
  record.last_login_date =
    record.last_login_date && dateVF({ value: record.last_login_date });
  record.last_work_date =
    record.last_work_date && dateVF({ value: record.last_work_date });

  // TODO: epStore.skykick.actionName2emailTemplate
  const emailTemplate = useStoreState(s => s.skykick.actionName2emailTemplate)[
    recommended_action
  ];

  if (recommended_action === NO_ACTION_REQUIRED) {
    return null;
  } else if (!emailTemplate) {
    return "";
  } else {
    return record2mailto(emailTemplate, record);
  }
}

export function RecommendedActionButtonCellRenderer({ data }) {
  const recommendedActionMailtoLink = useRecommendedActionMailtoLink(data);

  let minimal = false;
  let intent = "primary";
  let disabled = false;
  let onClick = () => window.open(recommendedActionMailtoLink);
  if (recommendedActionMailtoLink === null) {
    // minimal = true;
    intent = undefined;
    disabled = true;
    onClick = null;
  }
  return (
    <span
      css={`
        height: 26px;
        line-height: 26px;
      `}
    >
      <Button
        css={`
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
        `}
        fill={true}
        small={true}
        minimal={minimal}
        intent={intent}
        disabled={disabled}
        onClick={onClick}
      >
        {data.recommended_action}
      </Button>
    </span>
  );
}
