const routes = {
  dashboard: "/dashboard",
  signin: "/signin",
  profile: "/profile",
  root: "/",
  rollup: "/rollup",
  pulse: "/pulse",
  manual: "/manual",
  //
  napo: "/napo/:partnerId",
};
routes.DEFAULT = routes.dashboard;
export default routes;
