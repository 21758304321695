import React from "react";
import {
  Classes,
  Popover,
  PopoverInteractionKind,
  Position,
} from "@blueprintjs/core";
import { ChuScoPopoverContent } from "./popover-content";
import { ChuScoPopoverTarget } from "./popover-target";
import "styled-components/macro";

export function ChurnScoreCellRenderer({
  value: churnScore,
  data: { dp__PartnerId: partnerId },
}) {
  return <ChuScoPopover churnScore={churnScore} partnerId={partnerId} />;
}

function ChuScoPopover({ churnScore, partnerId }) {
  return (
    <Popover
      content={
        <ChuScoPopoverContent churnScore={churnScore} partnerId={partnerId} />
      }
      position={Position.RIGHT_TOP}
      interactionKind={PopoverInteractionKind.HOVER}
      popoverClassName={`${Classes.ELEVATION_4}`}
      hoverOpenDelay={300}
      fill={true}
      // targetClassName="bp3-fill"
    >
      <ChuScoPopoverTarget churnScore={churnScore} />
    </Popover>
  );
}
