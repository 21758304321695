import { action, computed, thunk } from "easy-peasy";
import {
  makeAuthorizedDeleteRequestToBackend,
  makeAuthorizedPostRequestToBackend,
  makeUrl,
} from "../helpers/backendApi";
import { message } from "antd";

const starsModel = {
  INITIAL_DATA_ENDPOINT: "stars",
  NAME: "stars",
  initialData: null,
  initialDataReceived: false,
  starPartner: thunk(
    async (actions, { partnerId, partnerName }, { getStoreState }) => {
      try {
        const resp = await makeAuthorizedPostRequestToBackend({
          url: makeUrl(`stars/${partnerId}`),
          data: {},
          axiosConfig: undefined,
        });
        message.success(`Successfully starred ${partnerName}`, 1);
        actions.receiveInitialData(resp.data);
      } catch (e) {
        message.error(`Failed to star ${partnerName}`);
      }
    }
  ),
  unstarPartner: thunk(
    async (actions, { partnerId, partnerName }, { getStoreState }) => {
      try {
        const resp = await makeAuthorizedDeleteRequestToBackend({
          url: makeUrl(`stars/${partnerId}`),
          axiosConfig: undefined,
        });
        message.success(`Successfully unstarred ${partnerName}`, 1);
        actions.receiveInitialData(resp.data);
      } catch (e) {
        message.error(`Failed to unstar ${partnerName}`);
      }
    }
  ),
  clear: action((state) => {
    state.initialData = null;
    state.initialDataReceived = false;
  }),
  receiveInitialData: action((state, payload) => {
    state.initialData = payload;
  }),
  starsSet: computed(
    ({ initialData }) => initialData && new Set([...initialData])
  ),
};

export { starsModel };
