import "styled-components/macro"; // DO NOT REMOVE. Necessary for using the css={`...`} prop
import * as COLORS from "../../styles/colors";
import { Button, Col, Row, Tooltip } from "antd";
import {
  AG_TABLE_RIBBON_BUTTON_IS_GHOST,
  AG_TABLE_RIBBON_BUTTON_SIZE,
  AG_TABLE_RIBBON_BUTTON_TOOLTIP_DELAY,
  AG_TABLE_RIBBON_BUTTON_TYPE,
  AG_TABLE_RIBBON_HEIGHT,
  AG_TABLE_RIBBON_HORIZONTAL_GUTTER
} from "../../styles/constants";
import React, { useCallback, useState } from "react";
import { Colors } from "@blueprintjs/core";
import { RbnSearchBox } from "./search-box";
import { useSearchBoxText } from "./search-box-store";
import { useFullScreenModeEnabled } from "../../store/model-misc";
import { useTableName } from "./my-ag-grid-react-context";
import { useGridBorderRadius } from "./use-grid-border-radius";
import { useStoreActions, useStoreState } from "../../hooks/ep";

const backgroundColor = Colors.BLUE1;
const activeBtnBgColor = "rgba(0, 0, 0, 0.1)";

export function Ribbon({ g, rowData }) {
  const brad = useGridBorderRadius();
  return (
    <Row
      type="flex"
      align="middle"
      justify="space-between"
      css={`
        transition: background 0.2s linear 0.2s;
        background: ${backgroundColor};
        padding: 0 ${AG_TABLE_RIBBON_HORIZONTAL_GUTTER}px;
        margin: 0;
        height: ${AG_TABLE_RIBBON_HEIGHT};
        flex-wrap: wrap;
        border-radius: ${brad} ${brad} 0 0;
      `}
    >
      <RibbonLeftHalf g={g} />
      <RibbonRightHalf g={g} rowData={rowData} />
    </Row>
  );
}

function useMyName() {
  // noinspection JSUnresolvedVariable
  return useStoreState(s => s.firebase.red.firebase.auth.displayName);
}

function RibbonLeftHalf({ g }) {
  const tableName = useTableName();

  return (
    <Col
      css={`
        padding-left: 6px;
      `}
    >
      <Row
        type="flex"
        justify="start"
        align="middle"
        gutter={AG_TABLE_RIBBON_HORIZONTAL_GUTTER}
        css={`
          margin: 0;
        `}
      >
        <RbnBtn
          title="Download as CSV"
          icon="download"
          onClick={g.exportToCsv}
        />
        <RbnBtn
          title="Auto-size columns"
          icon="column-width"
          onClick={g.autoSizeAll}
        />
        {tableName === "churn_risk" && (
          <RbnBtn
            title="Show/hide scrollbars"
            icon="drag"
            onClick={g.toggleScrollbarsVisible}
          />
        )}
        {["churn_risk", "rollup_detail"].includes(tableName) && (
          <OwnerBtn g={g} />
        )}
        {["churn_risk", "rollup_detail"].includes(tableName) && (
          <FullScreenBtn />
        )}

        {/*<RbnBtn*/}
        {/*  title="Toggle dark mode"*/}
        {/*  icon="bulb"*/}
        {/*  onClick={handleToggleDarkMode}*/}
        {/*/>*/}
      </Row>
    </Col>
  );
}

function OwnerBtn({ g }) {
  const myName = useMyName();

  const text = useSearchBoxText();
  const filterIsActive = text === myName;

  const nextText = filterIsActive ? "" : myName;
  const onClick = useCallback(() => g.setQuickFilterText(nextText), [nextText]);

  return (
    <RbnBtn
      title={
        filterIsActive
          ? "Reset ownership filter"
          : "Show only customers you manage"
      }
      icon="crown"
      onClick={onClick}
    />
  );
}

function FullScreenBtn() {
  const enabled = useFullScreenModeEnabled();
  // noinspection JSUnresolvedVariable
  const toggle = useStoreActions(a => a.misc.toggleFullScreenMode);

  return (
    <RbnBtn
      title={(enabled ? "Exit" : "Enter") + " full-screen mode"}
      icon={enabled ? "fullscreen-exit" : "fullscreen"}
      onClick={toggle}
    />
  );
}

function RibbonRightHalf({ g, rowData }) {
  const tableName = useTableName();
  return (
    <Col
      css={`
        padding-right: 4px;
      `}
    >
      <Row
        type="flex"
        justify="end"
        align="middle"
        gutter={AG_TABLE_RIBBON_HORIZONTAL_GUTTER}
        css={`
          margin: 0;
        `}
      >
        {["churn_risk", "rollup_detail"].includes(tableName) && (
          <Col
            css={`
              margin-right: 4px;
            `}
          >
            <RbnSearchBox rowData={rowData} onSearch={g.setQuickFilterText} />
          </Col>
        )}
        <RbnVisibilityBtn
          vmShown={g.vmShown}
          toggleVmShown={g.toggleVmShown}
          VisibilityModal={g.VisibilityModal}
        />
      </Row>
    </Col>
  );
}

export function RbnBtn({
  title,
  onClick,
  icon,
  onMouseEnter,
  onMouseLeave,
  tooltipDisabled = false,
  tooltipPlacement = "topLeft",
  hoverColor = COLORS.secondary,
  type,
  size,
  ghost,
  dataTestId,
  RootElement = Col
}) {
  let content = (
    <Button
      data-testid={dataTestId}
      type={type || AG_TABLE_RIBBON_BUTTON_TYPE}
      ghost={ghost || AG_TABLE_RIBBON_BUTTON_IS_GHOST}
      size={size || AG_TABLE_RIBBON_BUTTON_SIZE}
      icon={icon}
      //
      // To allow the buttons to return to their un-hovered, original color after being clicked
      onMouseDown={useCallback(e => e.preventDefault(), [])}
      //
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      css={`
        && {
          color: white;
          background: transparent;
          border: none;
          box-shadow: none;
        }
        &&:hover {
          transition: all 0.3s linear;
          color: ${hoverColor};
        }
        &&:active {
          background: ${activeBtnBgColor} !important;
        }
      `}
    />
  );

  if (!tooltipDisabled) {
    content = (
      <Tooltip
        title={title}
        placement={tooltipPlacement}
        mouseEnterDelay={AG_TABLE_RIBBON_BUTTON_TOOLTIP_DELAY}
      >
        {content}
      </Tooltip>
    );
  }
  return <RootElement>{content}</RootElement>;
}

function RbnVisibilityBtn({ toggleVmShown }) {
  const [icon, setIcon] = useState("eye");
  const onMouseEnter = useCallback(() => setIcon("eye-invisible"), [setIcon]);
  const onMouseLeave = useCallback(() => setIcon("eye"), [setIcon]);

  return (
    <RbnBtn
      title="Show/hide columns"
      icon={icon}
      onClick={toggleVmShown}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    />
  );
}
