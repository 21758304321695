import React, { useState } from "react";
import "styled-components/macro";
import { useEndpoint } from "../helpers/use-endpoint";
import { Switch, Tab, Tabs } from "@blueprintjs/core";
import { Spin } from "antd";
import { ScoresAndShapesTable } from "./ScoresAndShapesTable";
import { NivoLineFromEndpoint } from "./AgTable/nivo-line";
import { useBoolean } from "../helpers/useBoolean";
import { Facts } from "./facts";

const DEFAULT_TAB_ID = "facts";

export function PulsePage() {
  const [selectedTabId, setSelectedTabId] = useState(DEFAULT_TAB_ID);
  const handleTabChange = (newTabId, prevTabId, event) => {
    setSelectedTabId(newTabId);
  };
  const { loading, data } = useEndpoint("/pulse");

  if (loading) return <Spin size="large" />;
  return (
    <div
      css={`
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-start;
      `}
    >
      <Tabs
        onChange={handleTabChange}
        selectedTabId={selectedTabId}
        large
        renderActiveTabPanelOnly={true}
        css={`
          width: 100%;
          height: 100%;
          margin-top: -15px;
        `}
      >
        <Tab id="facts" title="Facts" panel={<Facts data={data.facts} />} />
        <Tab
          id="scores_and_shapes"
          title="Scores & Shapes"
          panel={<ScoresAndShapesTable />}
        />
        <Tab
          id="nao_history"
          title="Orders History"
          panel={<NaoHistoryLine />}
        />
        <Tab
          id="nap_history"
          title="Partners History"
          panel={
            <NivoLineFromEndpoint
              endpoint={"nap_history"}
              height={600}
              width={1000}
              enableSlices={false}
              enablePoints={false}
            />
          }
        />
        <Tab
          id="pce_history"
          title="Partner Churn History"
          panel={<PceHistoryLine />}
        />
      </Tabs>
    </div>
  );
}

function NaoHistoryLine() {
  const include_godaddy = useBoolean(true);
  const endpoint = `nao_history/${include_godaddy.value ? 1 : 0}`;
  return (
    <div>
      <Switch
        checked={include_godaddy.value}
        onChange={() => {
          include_godaddy.toggle();
        }}
        label="Include GoDaddy"
        large
        inline={false}
      />
      <NivoLineFromEndpoint
        endpoint={endpoint}
        height={600}
        width={1000}
        enableSlices={false}
        enablePoints={false}
      />
    </div>
  );
}

function PceHistoryLine() {
  const isCumulative = useBoolean(false);
  const endpoint = isCumulative.value
    ? "cumsum_pce_history"
    : "monthly_pce_history";
  return (
    <div>
      <Switch
        checked={isCumulative.value}
        onChange={() => {
          isCumulative.toggle();
        }}
        label="Cumulative"
        large
        inline={false}
      />
      <NivoLineFromEndpoint
        endpoint={endpoint}
        height={600}
        width={1000}
        enableSlices={false}
        enablePoints={false}
      />
    </div>
  );
}
