import { Spin } from "antd";
import React from "react";
import { useStoreState } from "../hooks/ep";
import { ChurnScoreAgTable } from "./explain-churn-score";
import { useEnsurePartnersData } from "../helpers/useEnsurePartnersData";
import { useMeData } from "../hooks/useMe";
import _ from "lodash";

export function BriefSkykickTable() {
  return useEnsurePartnersData() ? (
    <Spin size="large" />
  ) : (
    <BriefSkykickTableInner />
  );
}

function BriefSkykickTableInner() {
  const rowData = useStoreState((s) => s.skykick.rowData);
  const columnDefs = useColumnDefs();
  return <ChurnScoreAgTable rowData={rowData} columnDefs={columnDefs} />;
}

function useColumnDefs() {
  const myRole = useMeData().role;
  return applyRequiredRoleFilter(COLUMN_DEFS, myRole);
}

function applyRequiredRoleFilter(coldefs, myRole) {
  return coldefs.filter((cd) => satisfiesRequiredRole(cd, myRole));
}

function satisfiesRequiredRole(coldef, role) {
  const requiredRole = coldef["__requiredRole"];
  return _.isUndefined(requiredRole) || requiredRole === role;
}

const CHURNED__COLDEFS = [
  {
    headerName: "Is Churned",
    field: "is_churned",
    type: "booleanColumn",
  },
  {
    headerName: "Score Before Churned",
    field: "score_before_churned",
    type: "percentageColumn",
  },
  {
    headerName: "Days Since Churned",
    field: "days_since_churned",
    type: "integerColumn",
  },
];

const CAMPAIGN_ASSIGNMENTS__COLDEFS = [
  {
    headerName: "Campaign #1",
    field: "campaign1",
    type: "booleanColumn",
  },
  // {
  //   headerName: "Campaign #2",
  //   field: "campaign2",
  //   type: "booleanColumn",
  // },
  // {
  //   headerName: "Campaign #3",
  //   field: "campaign3",
  //   type: "booleanColumn",
  // },
];

const CAMPAIGN_DESIGN__COLDEFS = [
  {
    headerName: "Days Since Joined",
    field: "days_since_joined",
    type: "integerColumn",
  },
  {
    headerName: "Days Since 1st Backup Order",
    field: "days_since_1st_B_order",
    type: "integerColumn",
  },
  {
    headerName: "Days Since 1st Paying Order",
    field: "days_since_1st_PB_order",
    type: "integerColumn",
  },
  {
    headerName: "Total Days With Paying Orders",
    field: "days_with_gt0_PB_orders",
    type: "integerColumn",
  },
  {
    headerName: "Consecutive Days With Paying Orders",
    field: "consec_days_gt0_PB_orders",
    type: "integerColumn",
  },
  {
    headerName: "At-Risk Yearly Backup Revenue",
    field: "backup_ltv_at_risk__last_365_days",
    type: "moneyColumn",
  },
  {
    headerName: "Backup LTV",
    field: "backup_ltv",
    type: "moneyColumn",
  },
  {
    headerName: "Backup LTV (past 365 days)",
    field: "backup_ltv__last_365_days",
    type: "moneyColumn",
  },
  {
    headerName: "Backup LTV (past 180 days)",
    field: "backup_ltv__last_180_days",
    type: "moneyColumn",
  },
  {
    headerName: "Backup LTV (past 90 days)",
    field: "backup_ltv__last_90_days",
    type: "moneyColumn",
  },
  {
    headerName: "Backup LTV (past 30 days)",
    field: "backup_ltv__last_30_days",
    type: "moneyColumn",
  },
  {
    headerName: "Paying Orders >0⟶0 Events",
    field: "num_napo_to_0_events",
    type: "integerColumn",
  },
  {
    headerName: "Paying Orders >0⟶0 Events (past 365 days)",
    field: "num_napo_to_0_events__last_365_days",
    type: "integerColumn",
  },
  {
    headerName: "Paying Orders >0⟶0 Events (past 180 days)",
    field: "num_napo_to_0_events__last_180_days",
    type: "integerColumn",
  },
  {
    headerName: "Paying Orders >0⟶0 Events (past 90 days)",
    field: "num_napo_to_0_events__last_90_days",
    type: "integerColumn",
  },
  {
    headerName: "Paying Orders >0⟶0 Events (past 30 days)",
    field: "num_napo_to_0_events__last_30_days",
    type: "integerColumn",
  },
  {
    headerName: "Disti Usage",
    field: "distrib_usage",
    type: "categoryColumn",
  },
  {
    headerName: "Peak Paying Orders (past 365 days)",
    field: "peak_napo__last_365_days",
    type: "integerColumn",
  },
  {
    headerName: "Peak Paying Orders (past 180 days)",
    field: "peak_napo__last_180_days",
    type: "integerColumn",
  },
  {
    headerName: "Peak Paying Orders (past 90 days)",
    field: "peak_napo__last_90_days",
    type: "integerColumn",
  },
  {
    headerName: "Peak Paying Orders (past 30 days)",
    field: "peak_napo__last_30_days",
    type: "integerColumn",
  },
  {
    headerName: "# Paying Orders that Started as Monthly",
    field: "num_PB_orders_started_monthly",
    type: "integerColumn",
  },
  {
    headerName: "# Paying Orders that Started as Annual",
    field: "num_PB_orders_started_annual",
    type: "integerColumn",
  },
  {
    headerName: "% Paying Orders that Started as Monthly",
    field: "pct_PB_orders_started_monthly",
    type: "percentageColumn",
  },
  {
    headerName: "# Monthly Current Paying Orders",
    field: "num_current_PB_orders_monthly",
    type: "integerColumn",
  },
  {
    headerName: "# Annual Current Paying Orders",
    field: "num_current_PB_orders_annual",
    type: "integerColumn",
  },
  {
    headerName: "% Monthly Current Paying Orders",
    field: "pct_current_PB_orders_monthly",
    type: "percentageColumn",
  },
];

const COLUMN_DEFS = [
  {
    field: "starred",
    headerName: " ",
    type: "categoryColumn",
    cellClass: "react-rendered-cell",
    cellRenderer: "starCellRenderer",
    width: 60,
  },
  {
    field: "dp__PartnerName",
    headerName: "Name",
    type: "linkedTextColumn",
    __linkField: "dp__PartnerCrmUrl",
  },
  {
    headerName: "3 Month Churn Risk",
    field: "y: ChurnRiskScore",
    type: "floatColumn",
    cellRenderer: "churnScoreCellRenderer",
    cellClass: "react-rendered-cell",
    width: 200,
  },
  {
    headerName: "Bucket",
    field: "score_bucket_str",
    type: "categoryColumn",
  },
  {
    headerName: "Paying Orders",
    field: "napo",
    type: "integerColumn",
    cellRenderer: "napoCellRenderer",
  },
  {
    headerName: "Peak Paying Orders",
    field: "peak_napo",
    type: "integerColumn",
  },
  {
    headerName: "Shape",
    field: "napo_shape",
    type: "categoryColumn",
  },
  ...CAMPAIGN_ASSIGNMENTS__COLDEFS,
  ...CHURNED__COLDEFS,
  {
    headerName: "Sales Owner",
    field: "uo__fullname",
    type: "categoryColumn",
  },
  {
    headerName: "TAM Owner",
    field: "p__cv_sampartnerownername",
    type: "categoryColumn",
  },
  {
    headerName: "Last Login",
    field: "dp__LastLoginDate",
    type: "dateColumn",
  },
  {
    headerName: "Active Orders",
    field: "la__SUM(fbms__ActiveOrder)",
    type: "integerColumn",
  },
  {
    headerName: "Consecutive Backup Months",
    field: "la__fbms__ConsecutivePreviousMonthsActive",
    type: "integerColumn",
  },
  // {
  //   headerName: "Partner Tier",
  //   field: "p__cv_partnertiername",
  //   type: "categoryColumn"
  // },
  {
    headerName: "Segment",
    field: "segment",
    type: "categoryColumn",
  },
  {
    headerName: "Region",
    field: "region",
    type: "categoryColumn",
  },
  {
    headerName: "Sub-Region",
    field: "subregion",
    type: "categoryColumn",
  },
  {
    headerName: "Mgmt Status",
    field: "p__cv_managementstatusname",
    type: "categoryColumn",
  },
  {
    headerName: "Mgmt Status Effective",
    field: "p__cv_datemanagementstatuseffective",
    type: "dateColumn",
  },
  {
    headerName: "Active Seats",
    field: "la__SUM(fbms__Seats)",
    type: "integerColumn",
  },
  {
    headerName: "Active Licenses",
    field: "la__SUM(fbms__Licenses)",
    type: "integerColumn",
  },
  {
    headerName: "Primary Contact",
    field: "dp__PrimaryContact",
    type: "linkedTextColumn",
    __linkField: "dp__Email",
  },
  {
    headerName: "Net ∆ Orders (this month)",
    field: "la__SUM(fbms__NetOrders)",
    type: "integerColumn",
  },
  {
    headerName: "Net ∆ Seats (this month)",
    field: "la__SUM(fbms__NetSeats)",
    type: "integerColumn",
  },
  {
    headerName: "Net ∆ Licenses (this month)",
    field: "la__SUM(fbms__NetLicenses)",
    type: "integerColumn",
  },
  {
    headerName: "# Open Tasks",
    field: "nopen__tasks",
    type: "integerColumn",
  },
  {
    headerName: "# Open SWAT Tickets",
    field: "nopen__swat_tickets",
    type: "integerColumn",
  },
  ...CAMPAIGN_DESIGN__COLDEFS,
  {
    headerName: "",
    field: "action",
    cellClass: "react-rendered-cell",
    cellRenderer: "actionButtonCellRenderer",
    width: 48,
    pinned: "right",
  },
];
