import routes from "./routes";

export const leftNavMenuItems = [
  // {
  //   urlPath: routes.skykick_partners,
  //   title: "Tables",
  //   iconType: "table"
  // },
  // {
  //   urlPath: routes.settings,
  //   title: "Settings",
  //   iconType: "setting"
  // },
  {
    urlPath: routes.dashboard,
    title: "Dashboard",
    iconType: "dashboard",
  },
  {
    urlPath: routes.rollup,
    title: "Rollup",
    bp3IconType: "grid-view",
  },
  {
    urlPath: routes.pulse,
    title: "Pulse",
    bp3IconType: "pulse",
  },
  {
    urlPath: routes.manual,
    title: "Manual",
    bp3IconType: "info-sign",
  },
];
