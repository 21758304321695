import React from "react";
import "styled-components/macro";
import { Classes, Tag } from "@blueprintjs/core";
import { Bar, ResponsiveBar } from "@nivo/bar";
import { calculateTotal } from "./nivo-sunburst";
import { NIVO_LINE_THEME } from "../AgTable/nivo-line-theme";
import { integerVF } from "../AgTable/gridOptions";
import _ from "lodash";

export function NivoBar({ normalizeBarHeights, width, height, data, keys }) {
  if (!keys) {
    keys = new Set([]);
    data.children.forEach(top => {
      top.children.forEach(bot => {
        keys.add(bot.id);
      });
    });
    keys = [...keys];
  }

  const totals = calculateTotal(data.children);

  const group2id2cnt = {};
  const group2id2pct = {};
  data.children.forEach(top => {
    if (!group2id2cnt[top.id]) group2id2cnt[top.id] = {};
    if (!group2id2pct[top.id]) group2id2pct[top.id] = {};
    top.children.forEach(bot => {
      group2id2cnt[top.id][bot.id] = bot.value;
      group2id2pct[top.id][bot.id] =
        (100 * bot.value) / totals.find(el => el.id === top.id).total;
    });
    keys.forEach(k => {
      group2id2cnt[top.id][k] = group2id2cnt[top.id][k] ?? 0;
      group2id2pct[top.id][k] = group2id2pct[top.id][k] ?? 0;
    });
  });

  const margin = { top: 40, right: 160, bottom: 40, left: 10 };

  const barData = data.children.map(d => {
    let ret = { group: d.id };
    const total = totals.find(e => e.id === d.id)?.total || 1;
    d.children.forEach(ch => {
      ret[ch.id] = normalizeBarHeights ? ch.value / total : ch.value; // < onePct ? Math.round(onePct) : ch.value;
    });
    return ret;
  });

  const formatBottomAxisTicks = group => {
    const total = totals.find(el => el.id === group)?.total ?? 0;
    return `${group} (${integerVF(total)})`;
  };

  return (
    // <div
    //   css={`
    //     width: ${width}px;
    //     height: ${height}px;
    //   `}
    // >
    <Bar
      width={width}
      height={height}
      label={d => {
        const value = (group2id2cnt[d.indexValue] ?? {})[d.id] ?? 0;
        const pct = (group2id2pct[d.indexValue] ?? {})[d.id] ?? 0;
        return `${integerVF(value)} (${Math.round(pct)}%)`;
      }}
      data={barData}
      keys={keys}
      indexBy="group"
      margin={margin}
      padding={0.3}
      theme={NIVO_LINE_THEME}
      colors={{ scheme: "category10" }}
      borderColor={"rgba(0,0,0,0)"}
      layout={"vertical"}
      borderWidth={0}
      axisTop={null}
      axisRight={null}
      axisLeft={null}
      axisBottom={{
        tickSize: 0,
        tickPadding: 20,
        tickRotation: 0,
        legend: "",
        legendPosition: "middle",
        legendOffset: 0,
        format: formatBottomAxisTicks
      }}
      enableGridY={false}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor="#ffffff"
      animate={true}
      motionStiffness={90}
      motionDamping={15}
      tooltip={tooltipRendererFactory(group2id2cnt, group2id2pct)}
      isInteractive={true}
      legends={[
        {
          dataFrom: "keys",
          anchor: "right",
          direction: "column",
          justify: false,
          translateX: 100,
          translateY: 0,
          itemWidth: 120,
          itemHeight: 20,
          itemsSpacing: 8,
          symbolSize: 12,
          itemDirection: "left-to-right",
          textColor: "#fff",
          itemTextColor: "#fff"
        }
      ]}
    />
    // </div>
  );
}

function tooltipRendererFactory(group2id2cnt, group2id2pct) {
  return ({ id, indexValue, value }) => {
    const pct = ((group2id2pct[indexValue] ?? {})[id] ?? 0.0).toFixed(1);
    const cnt = (group2id2cnt[indexValue] ?? {})[id] ?? 0;
    return (
      <Tag
        large
        className={`${Classes.ELEVATION_1}`}
        css={`
          color: #fff !important;
          background-color: rgba(0, 25, 43, 0.8) !important;
        `}
      >
        {`${id}: ${integerVF(cnt)} (${pct}%)`}
      </Tag>
    );
  };
}
