import { Callout, Card, Elevation, H4, H5 } from "@blueprintjs/core";
import "styled-components/macro";
import React from "react";

export function Facts({ data }) {
  return (
    <div
      css={`
        margin-top: 72px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        & .bp3-card.bp3-card {
          padding: 20px;
        }
      `}
    >
      {SECTIONS.map(({ title, whitelist }) => (
        <Section data={data} title={title} whitelist={whitelist} />
      ))}
    </div>
  );
}

const SECTIONS = [
  {
    title: "Churn Stats - Overall",
    whitelist: [
      "Number of scored partners",
      "Number of partners that churned after launch",
      "Number of scored partners that churned",
      "Number of onboarding partners that churned after launch",
      "Number of scored partners that were retained",
    ],
  },
  {
    title: "Churn Stats - Score",
    whitelist: [
      "Percent of scored partners that churned",
      // "Percent of churned partners with above-average scores",
      "Percent of partners with above-mean scores that churned",
      "Percent of partners with above-median scores that churned",
      "Percent of partners with avg scores above 10% that churned",
      "Percent of partners with avg scores above 20% that churned",
    ],
  },
  {
    title: "Score Stats",
    whitelist: [
      "Average score",
      "Average score of churned partners",
      "Average score of retained partners",
      "Ratio of average churned partner score to average retained partner score",
    ],
  },
  {
    title: "Misc. Stats",
    whitelist: [
      "Average number of active orders held by scored partner just before churn",
      "Number of scored partners that have churned with more than one active order",
      "Number of scored partners that were won back after churn",
      "Number of scored partner churn events (including re-churn)",
    ],
  },
];

function Section({ data, title, whitelist }) {
  return (
    <Card
      css={`
        width: 23%;
      `}
      elevation={Elevation.TWO}
    >
      <H4>{title}</H4>
      <div
        css={`
          width: 100%;
          & > * {
            margin: 16px 0;
          }
        `}
      >
        {data
          .filter((d) => whitelist.includes(d.name))
          .map((d, idx) => (
            <Fact key={idx} {...d} />
          ))}
      </div>
    </Card>
  );
}

function Fact(props) {
  return (
    // <Card elevation={Classes.ELEVATION_2}>
    <Callout intent="primary" icon={null} fill={false}>
      <H5>{props.name}</H5>
      {props.content}
    </Callout>
    // </Card>
  );
}
